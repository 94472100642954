import React, { useState } from "react";
import { SketchPicker } from "react-color";

function ColorSelector({color, setColor}) {


    const customStyles = {
        default: {
            picker: {
                backgroundColor: "transparent", // Transparent background
                boxShadow: "none", // Remove shadow
            },
            input: {
                backgroundColor: "transparent",
            }
        },
    };

    return (
        <div>
            <SketchPicker
                presetColors={[]}
                color={color}
                onChange={(c) => setColor(c.hex)}
                styles={customStyles} // Apply custom styles
            />
        </div>
    );
}

export default ColorSelector;