import React, { useState } from 'react';
import "../data/App.css"
import axios from "axios";
import TextFieldComp from "../TextFieldComponent";
import ToolTip from "../ToolTip";
import baseLink from "../data/api-base-link";
import CheckboxComp from "../CheckboxComp";
import ColorSelector from "../ColorSelector";


function Settings({ paymentMethods, fingerPrint, token, setError, setSuccess, isAdmin}) {
    const [adminSettings, setAdminSettings] = useState({});
    const [color, setColor] = useState("#ffffff");

    const convertToDisplay = (methods) => {
        const converted = {};
        for (const method in methods) {
            converted[method] = {
                ...methods[method],
                fee: ((methods[method].fee - 1) * 100).toFixed(2).toString(), // Convert to percentage
            };
        }
        return converted;
    };

    const handleSettingChange = (key, value) => {
        setAdminSettings((prevSettings) => {
            if (value.trim() === "") {
                // Remove key if value is empty
                const { [key]: _, ...rest } = prevSettings;
                return rest;
            }
            // Update or add key with value
            return { ...prevSettings, [key]: value };
        });
    };

    const convertToDataFormat = (methods) => {
        const converted = {};
        for (const method in methods) {
            converted[method] = {
                ...methods[method],
                fee: (parseFloat(methods[method].fee) / 100 + 1), // Convert back to original format
            };
        }
        return converted;
    };

    // rendering logic for middle section where you select a game based off the product
    const [keyFix, setKeyFix] = useState("")
    const [paypalName, setPaypalName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [pMethodSettings, setPMethodSettings] = useState(convertToDisplay(paymentMethods))

    const handleKeyFixChange = (event) => {
        setKeyFix(event.target.value);
    }
    const handlePaypalNameChange = (event) => {
        setPaypalName(event.target.value);
    }

    const handleUserSaveSettings = async () => {
        const settingsDict = {}

        if (!(keyFix === "")) {
            settingsDict.keyfix = keyFix
        }
        if (!(paypalName === "")) {
            settingsDict.paypal = paypalName
        }
        if (!(emailAddress === "")) {
            settingsDict.email = emailAddress
        }

        if (Object.keys(settingsDict).length === 0) {
            setError("No settings to edit")
            return
        }

         try {
             const response = await axios.post(`https://${baseLink}/settings/user/` + token + "?nocache=", settingsDict);
             if (response.status === 200) {
                 setSuccess(response.data);
                 // handle success message
             }
         }
         catch(error)
             {
                 if (error.response) {
                     if (error.response.status === 401) {
                         setError("Login token expired, please login again.");
                         // add code to auto logout
                     } else if (error.response.status === 500) {
                         setError("Server error. Please try again later.");
                     } else if (error.response.status === 429) {
                         setError("Too many requests, please wait 1 minute.");
                     }
                 } else {
                     setError("An unexpected error occurred.");
                 }
             }
    }


    const handleFeeChange = async (e, method) => {
        let input = e.target.value;
        if (input === "") {
            input = "0"
        }
        if (input.length > 1 && input.startsWith("0")) {
            input = input.substring(1, input.length);
        }

        if (parseFloat(input) >= 0 && parseFloat(input) < 100 || input === "") {
            setPMethodSettings((prevSettings) => ({
                ...prevSettings,
                [method]: {
                    ...prevSettings[method],
                    fee: input === "" ? "0" : input
                }
            }));
        }
    }

    const handleKeyValueChange = (e, method, key) => {
        const newValue = e.target.value;
        setPMethodSettings((prevSettings) => ({
            ...prevSettings,
            [method]: {
                ...prevSettings[method],
                [key]: newValue
            }
        }));
    };

    const handleAdminSettingsChange = async () => {
        let dataDict = {}

        for (const [key, data] of Object.entries(pMethodSettings)) {
            if (data.enabled && data.address === "") {
                setError(`${key.substring(0,1).toUpperCase()}${key.substring(1,key.length)} is enabled but address is empty. Either disable it or provide an address.`)
                return
            }
        }

        dataDict['paymentmethods'] = convertToDataFormat(pMethodSettings);


    return

        try {
            const response = await axios.post(`https://desync.mysrv.us/settings/admin/${token}/?nocache=`, dataDict);
            if (response.status === 200) {
                setSuccess(response.data); // Clear any previous error message
                // handle success message
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Login token expired, please login again.");
                    // add code to auto logout
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                }
            } else {
                setError("An unexpected error occurred.");
            }
        }
    };





    return (
        <div className="PageDefaults">
            <div className={"BoxItemFlexBox"}>
                <div className="KeyManage">
                    <div className={"InputKey"}>
                        <h2>User Settings</h2>
                        <div className="BoxItem">
                            <div>
                                <p>Set Key Prefix</p>
                                <TextFieldComp label={"Keyfix"} type={"text"} placeholder={"Less than 8 characters"}
                                               onChangeFunc={handleKeyFixChange} inputProps={{maxLength: 8}}
                                               isMultiline={false}/>
                            </div>
                            <div>
                                <div className={"toolTipContainer"} style={{margin: "1ch 0 1ch 0"}}>
                                    <p>Set Paypal Name</p>
                                    <ToolTip tooltipText={"If you are paying with paypal, you must set your paypal name here <em>exactly</em> how it is written on your paypal account."}/>
                                </div>

                                <TextFieldComp type={"text"} placeholder={"Case Sensitive"}
                                               onChangeFunc={handlePaypalNameChange} label={"Paypal Name"}/>
                            </div>
                            <div>
                                <div>
                                    <p>Add Email</p>
                                    <TextFieldComp type={"text"} placeholder={"example@email.com"}
                                                   onChangeFunc={e => setEmailAddress(e.target.value)} label={"Email Address"}/>
                                </div>

                                <div>
                                    <button onClick={handleUserSaveSettings}>
                                        Save Info
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {isAdmin && (
                    <>
                        <div className={"vSort"} style={{alignItems: "center"}}>
                            <h2>Channel Settings</h2>
                            <div className="BoxItem">
                                <div className={"BoxScrollContainer"}>
                                    <p>Logging Channel</p>
                                    <TextFieldComp type={"text"}
                                                   onChangeFunc={e => handleSettingChange("LOG_CHANNEL", e.target.value)}
                                                   label={"Channel ID"}/>
                                    <p>Order Alert Channel</p>
                                    <TextFieldComp type={"text"}
                                                   onChangeFunc={e => handleSettingChange("ORDER_CHANNEL", e.target.value)}
                                                   label={"Channel ID"}/>
                                    <p>API Order Alert Channel</p>
                                    <TextFieldComp type={"text"}
                                                   onChangeFunc={e => handleSettingChange("API_ORDER_CHANNEL", e.target.value)}
                                                   label={"Channel ID"}/>
                                    <p>Low Stock Alert Channel</p>
                                    <TextFieldComp type={"text"}
                                                   onChangeFunc={e => handleSettingChange("STOCK_ALERT_CHANNEL", e.target.value)}
                                                   label={"Channel ID"}/>
                                    <button>Save Channels</button>
                                </div>
                            </div>
                        </div>
                        <div className={"vSort"} style={{alignItems: "center"}}>
                            <h2>Embed Settings</h2>
                            <div className="BoxItem">
                                <div className={"BoxScrollContainer"}>
                                    <p>Embed Footer Text</p>
                                    <TextFieldComp type={"text"}
                                                   onChangeFunc={e => handleSettingChange("FOOTERTEXT", e.target.value)}
                                                   label={"Footer Text"}/>
                                    <p>Embed Footer Icon</p>
                                    <TextFieldComp type={"text"}
                                                   onChangeFunc={e => handleSettingChange("FOOTERICON", e.target.value)}
                                                   label={"URL"} placeholder={"https://..."}/>
                                    <p>Embed Color</p>
                                    <div className={"vSort"} style={{alignItems: "center"}}>
                                        <ColorSelector color={color} setColor={setColor}/>
                                    </div>
                                    <button>Save Embeds</button>
                                </div>
                            </div>
                        </div>
                        <div className={"vSort"} style={{alignItems: "center"}}>
                            <h2>Payment Settings</h2>
                            <div className="BoxItem">
                                <div className={"BoxScrollContainer"} style={{maxHeight: "800px"}}>


                                {Object.entries(pMethodSettings).map(([method, data]) => (
                                    <>
                                        <p>{method.toUpperCase().substring(0, 1) + method.substring(1, method.length)}</p>
                                        <div className={"vSort"}>
                                            {data.address !== undefined && (
                                                <TextFieldComp
                                                    type="text"
                                                    value={data.address}
                                                    onChangeFunc={e => handleKeyValueChange(e, method, "address")}
                                                    label="Address"
                                                />
                                            )}

                                            {data.apikey !== undefined && (
                                                <TextFieldComp
                                                    type="text"
                                                    value={data.apikey}
                                                    onChangeFunc={e => handleKeyValueChange(e, method, "apikey")}
                                                    label="Address"
                                                />
                                            )}


                                            <div className={"hSort"}>
                                                <TextFieldComp
                                                    boxWidth={"12ch"}
                                                    type="text"
                                                    value={data.fee.toString()}
                                                    onChangeFunc={e => handleFeeChange(e, method)}
                                                    label="Fee"
                                                    endSymbol={"%"}
                                                />
                                                <CheckboxComp
                                                    label="Enabled"
                                                    checked={data['enabled']}
                                                    onChangeFunc={(e) => {
                                                        setPMethodSettings((prevSettings) => ({
                                                            ...prevSettings,
                                                            [method]: {
                                                                ...prevSettings[method],
                                                                enabled: e.target.checked
                                                            }
                                                        }));
                                                    }}
                                                />


                                            </div>

                                        </div>

                                    </>
                                ))}
                                </div>
                                <div>
                                    <button onClick={handleAdminSettingsChange}>Save Payments</button>
                                </div>

                            </div>
                        </div>
                    </>

                )}

            </div>
        </div>
    );
}

export default Settings;