import React, {useEffect, useRef, useState} from 'react';
import "../data/App.css"
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import baseLink from "../data/api-base-link";
import myTheme from "../data/mui-theme";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ToolTip from "../ToolTip";
import TextFieldComp from "../TextFieldComponent";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";



function SEO({fingerPrint, token, categoryData, setCategories, productData, setGameData, setError, setSuccess}) {
    const historyListRef = useRef(null);
    const [activeItem, setActiveItem] = useState("");
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [productArticle, setProductArticle] = useState("");
    const [tags, setTags] = useState({meta:{title: "", desc: "", keywords: ""}, og:{title: "", desc: "", keywords: ""}, twitter:{title: "", desc: "", card: ""} })
    const [faqDict, setFaqDict] = useState({});
    const [tagCategory, setTagCategory] = useState("");
    const [articleTitle, setArticleTitle] = useState("");
    const [articleBody, setArticleBody] = useState("");
    const [articlePopup, setArticlePopup] = useState(false);
    const [articlePath, setArticlePath] = useState(null);
    const tagStringDict = {"og": "Open Graph", "twitter":"Twitter", "meta":"Meta"}

    const handleArticlePopup = () =>{
        setArticlePopup(!articlePopup)
        setArticleTitle("")
        setArticleBody("")
    }

    const handleTagCategory = (event) => {
        setTagCategory(event.target.value);
    }

    const handleProductSelection = (event) => {
        setSelectedProduct(event);
        setSelectedCategory(""); // Clear category selection
        setActiveItem(event)
        setFaqDict(productData[event].seo.faq)
        setTags(productData[event].seo.tags)
    };

    const updateTag = (section, key, value) => {
        setTags(prevTags => ({
            ...prevTags,
            [section]: {
                ...prevTags[section],
                [key]: value
            }
        }));
    };

    const addNewFAQ = () => {
        let name = "New Question"
        let count = 1;
        while(true) {
            if (name in faqDict) {
                name = `New Question ${count}`
                count++;
            }

            else{
                break
            }
        }
        setFaqDict(prevDict => ({
            ...prevDict,
            [name]: ""
        }));

    }

    const handleDeleteFaq = (name) => {
        setFaqDict(prevDict => {
            const updatedDict = { ...prevDict };
            delete updatedDict[name]; // Remove the feature by name
            return updatedDict;
        });
    };

    const handleQuestionChange = (event, index) => {
        let newName = event.target.value;

        if (newName in faqDict) {
            newName = newName+"-1"
        }

        setFaqDict(prevDict => {
            const entries = Object.entries(prevDict);
            const updatedEntry = [newName, entries[index][1]]; // Replace name with new name, keep features unchanged

            return Object.fromEntries([
                ...entries.slice(0, index),
                updatedEntry,
                ...entries.slice(index + 1)
            ]);
        });
    };

    const handleSetQAnswer = (event, category) => {
        setFaqDict(prevDict => ({
            ...prevDict,
            [category]: event.target.value
        }));
    }

    const handleCategorySelection = (event) => {
        setSelectedCategory(event);
        setSelectedProduct(""); // Clear product selection
        setActiveItem(event)
        setTags(categoryData[event].seo.tags)
        setFaqDict(categoryData[event].seo.faq)
    };


    useEffect(() => {
        const handleScroll = () => {
            const list = historyListRef.current;
            if (list) {
                const rows = list.querySelectorAll('.row');
                rows.forEach(row => {
                    const rect = row.getBoundingClientRect();
                    const listRect = list.getBoundingClientRect();

                    // Calculate visible height within the list viewport
                    const visibleHeight = Math.min(rect.bottom, listRect.bottom) - Math.max(rect.top, listRect.top);

                    // Ensure the visible height is within valid bounds
                    const clampedVisibleHeight = Math.max(0, Math.min(rect.height, visibleHeight));

                    // Calculate visibility percentage
                    const visibilityPercentage = clampedVisibleHeight / rect.height;

                    // Apply styles based on visibility
                    if (visibilityPercentage > 0 && visibilityPercentage < 1) {
                        row.style.opacity = visibilityPercentage.toString();
                        row.style.transform = `scale(${visibilityPercentage})`;
                        row.style.visibility = 'visible';
                    } else if (visibilityPercentage <= 0) {
                        row.style.opacity = '0';
                        row.style.transform = 'scale(0)';
                        row.style.visibility = 'hidden'; // Hide the row completely
                    } else {
                        row.style.opacity = '1';
                        row.style.transform = 'scale(1)';
                        row.style.visibility = 'visible';
                    }
                });
            }
        };

        const list = historyListRef.current;
        if (list) {
            list.addEventListener('scroll', handleScroll);
            // Call handleScroll initially to set the correct state on load
            handleScroll();
        }

        return () => {
            if (list) {
                list.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    // add useEffect for retrieving userdata here, should only run once

    const newArticle = async () => {
        let settingsDict = {title: articleTitle, body: articleBody, path: articlePath}

        try {
            const response = await axios.post(`https://${baseLink}/articles/new` + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess("Success")
            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const setSeoData = async() => {
        let cType = "category";
        if (!(selectedProduct ==="")) {
            cType = "product"
        }
        let settingsDict = {type:cType ,data: {"details":productArticle, "tags": tags, 'faq': faqDict}}

        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/seo/` + activeItem + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess("Success")
                if (!(selectedProduct === "")) {
                    setGameData(prevData => ({
                        ...prevData,
                        [activeItem]: {
                            ...prevData[activeItem], // Spread the existing product data
                            ...response.data.productData // Overwrite with the new data
                        }
                    }));
                }

                else {
                    setSuccess("Success")
                    setCategories(prevData => ({
                        ...prevData,
                        [activeItem]: {
                            ...prevData[activeItem], // Spread the existing product data
                            ...response.data.category // Overwrite with the new data
                        }
                    }));
                }


            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }


    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setSuccess('Copied to clipboard');
                // You can add a success message or other feedback here if needed
            })
            .catch((err) => {
                setError('Failed to copy to clipboard');
            });
    };

    return (
        <div className="PageDefaults">
            {articlePopup && (
                <div className="popup">
                    <div className="popup-content-large">
                        <span className="close-btn" onClick={handleArticlePopup}>&times;</span>
                        <div className={"BoxScrollContainer"}>
                            <div className={"vSort"}>
                                <p>Remember to Save!</p>
                                <TextFieldComp label={"Article Title"} value={articleTitle} onChangeFunc={e => setArticleTitle(e.target.value)} placeholder={"Enter interesting title..."} />
                                <TextFieldComp label={"Article Path"} value={articlePath} onChangeFunc={e => setArticlePath(e.target.value)} placeholder={"Only characters and dashes"} />
                                <TextFieldComp label={"Article Body"} value={articleBody} onChangeFunc={e=> setArticleBody(e.target.value)} placeholder={"Enter body..."} />
                                <div>
                                    <button onClick={newArticle}>Save Article</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={"BoxItemFlexBox"}>
                <div className={"ProductManage"}>
                    <h2>SEO Tags</h2>
                    <div>
                        <div className={"BoxItem"}>
                            <p>Select Item</p>
                            <ThemeProvider theme={myTheme}>
                                <Box sx={{
                                    '& > :not(style)': {
                                        m: 1,
                                        width: '25ch',
                                        color: myTheme.palette.secondary.main,
                                        backgroundColor: myTheme.palette.background.main,
                                    },
                                    '& .MuiSelect-icon': {
                                        color: myTheme.palette.primary.main, // Set arrow color to match primary color
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: myTheme.palette.primary.main, // Set outline color to match primary color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: myTheme.palette.primary.main, // Set outline color on hover to match primary color
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: myTheme.palette.primary.main, // Set outline color when focused to match primary color
                                        },
                                    },
                                }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"
                                                    style={{color: myTheme.palette.primary.main}}>
                                            {"Select Product"}
                                        </InputLabel>
                                        <Select
                                            label={"Select Product"}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedProduct}
                                            onChange={(event) => handleProductSelection(event.target.value)}
                                            sx={{color: "white"}}>
                                            {productData && Object.keys(productData).map((game, index) => (
                                                <MenuItem key={index} value={game}>{game}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{
                                    '& > :not(style)': {
                                        m: 1,
                                        width: '25ch',
                                        color: myTheme.palette.secondary.main,
                                        backgroundColor: myTheme.palette.background.main,
                                    },
                                    '& .MuiSelect-icon': {
                                        color: myTheme.palette.primary.main, // Set arrow color to match primary color
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: myTheme.palette.primary.main, // Set outline color to match primary color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: myTheme.palette.primary.main, // Set outline color on hover to match primary color
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: myTheme.palette.primary.main, // Set outline color when focused to match primary color
                                        },
                                    },
                                }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"
                                                    style={{color: myTheme.palette.primary.main}}>
                                            {"Select Category"}
                                        </InputLabel>
                                        <Select
                                            label={"Select Category"}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedCategory}
                                            onChange={(event) => handleCategorySelection(event.target.value)}
                                            sx={{color: "white"}}>
                                            {categoryData && Object.keys(categoryData).map((game, index) => (
                                                <MenuItem key={index} value={game}>{game}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </ThemeProvider>
                            {activeItem && (
                                <>
                                    <p><em>{selectedProduct ? ("Product") : "Category"}:</em> {activeItem}</p>


                                    <p>SEO Page Tags</p>
                                    <ThemeProvider theme={myTheme}>
                                        <Box sx={{
                                            '& > :not(style)': {
                                                m: 1,
                                                width: '25ch',
                                                color: myTheme.palette.secondary.main,
                                                backgroundColor: myTheme.palette.background.main,
                                            },
                                            '& .MuiSelect-icon': {
                                                color: myTheme.palette.primary.main, // Set arrow color to match primary color
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: myTheme.palette.primary.main, // Set outline color to match primary color
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: myTheme.palette.primary.main, // Set outline color on hover to match primary color
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: myTheme.palette.primary.main, // Set outline color when focused to match primary color
                                                },
                                            },
                                        }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label"
                                                            style={{color: myTheme.palette.primary.main}}>
                                                    {"Select Category"}
                                                </InputLabel>
                                                <Select
                                                    label={"Select Category"}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={tagCategory}
                                                    onChange={(event) => handleTagCategory(event)}
                                                    sx={{color: "white"}}>
                                                    {categoryData && Object.keys(tags).map((type, index) => (
                                                        <MenuItem key={index} value={type}>{tagStringDict[type]}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </ThemeProvider>

                                    <div className={"vSort"}>
                                        {tagCategory === "meta" && (
                                            <>
                                                <p>Meta Tags</p>

                                                <TextFieldComp
                                                    label="Title"
                                                    type="text"
                                                    isMultiline={false}
                                                    onChangeFunc={(e) => updateTag("meta", "title", e.target.value)}
                                                    placeholder="Webpage Title"
                                                    value={tags.meta.title}
                                                />
                                                <TextFieldComp
                                                    label="Details"
                                                    type="text"
                                                    isMultiline={false}
                                                    onChangeFunc={(e) => updateTag("meta", "desc", e.target.value)}
                                                    placeholder="Webpage Description"
                                                    value={tags.meta.desc}
                                                />
                                                <TextFieldComp
                                                    label="Keywords"
                                                    type="text"
                                                    isMultiline={true}
                                                    onChangeFunc={(e) => updateTag("meta", "keywords", e.target.value)}
                                                    placeholder="Separate by newline"
                                                    value={tags.meta.keywords}
                                                />
                                            </>
                                        )}

                                        {tagCategory === "og" && (
                                            <>
                                                <p>OpenGraph Tags</p>
                                                <TextFieldComp
                                                    label="Title"
                                                    type="text"
                                                    isMultiline={false}
                                                    onChangeFunc={(e) => updateTag("og", "title", e.target.value)}
                                                    placeholder="OG Title"
                                                    value={tags.og.title}
                                                />
                                                <TextFieldComp
                                                    label="Details"
                                                    type="text"
                                                    isMultiline={false}
                                                    onChangeFunc={(e) => updateTag("og", "desc", e.target.value)}
                                                    placeholder="OG Description"
                                                    value={tags.og.desc}
                                                />
                                                <TextFieldComp
                                                    label="Keywords"
                                                    type="text"
                                                    isMultiline={true}
                                                    onChangeFunc={(e) => updateTag("og", "keywords", e.target.value)}
                                                    placeholder="Separate by newline"
                                                    value={tags.og.keywords}
                                                />
                                            </>
                                        )}

                                        {tagCategory === "twitter" && (
                                            <>
                                                <p>Twitter Tags</p>
                                                <TextFieldComp
                                                    label="Title"
                                                    type="text"
                                                    isMultiline={false}
                                                    onChangeFunc={(e) => updateTag("twitter", "title", e.target.value)}
                                                    placeholder="Twitter Title"
                                                    value={tags.twitter.title}
                                                />
                                                <TextFieldComp
                                                    label="Details"
                                                    type="text"
                                                    isMultiline={false}
                                                    onChangeFunc={(e) => updateTag("twitter", "desc", e.target.value)}
                                                    placeholder="Twitter Description"
                                                    value={tags.twitter.desc}
                                                />
                                                <TextFieldComp
                                                    label="Card"
                                                    type="text"
                                                    isMultiline={true}
                                                    onChangeFunc={(e) => updateTag("twitter", "card", e.target.value)}
                                                    placeholder="https://..."
                                                    value={tags.twitter.card}
                                                />
                                            </>
                                        )}


                                    </div>
                                </>
                            )}

                            <div>
                                <button onClick={setSeoData}>Save All SEO</button>
                            </div>
                            <div>
                                <button onClick={handleArticlePopup}>Create New Article</button>
                            </div>

                        </div>

                    </div>
                </div>
                {activeItem && (


                    <div>
                    <div style={{textAlign: "center"}}>
                            <h2>SEO Articles</h2>
                        </div>
                        <div className={"BoxItem"}>
                            <div className={"toolTipContainer"}>
                                <p>Main Product Article</p>
                                <ToolTip
                                    tooltipText={"It is recommended to type out the article somewhere else and paste it here once done."}/>
                            </div>
                                <ThemeProvider theme={myTheme}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': {
                                                m: 1,
                                                width: '25ch',
                                                color: "white",
                                                backgroundColor: myTheme.palette.background.main,
                                            },
                                            '& input': {
                                                color: 'white', // Set text color to white
                                            },
                                            '& .MuiInputBase-inputMultiline': {
                                                color: 'white', // Set text color to white for multiline input
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                },
                                            }, maxHeight: '150px',
                                            overflow: 'auto'

                                        }}
                                        noValidate
                                        autoComplete="off">

                                        <TextField variant={"outlined"} color={"primary"}
                                                   value={productArticle} multiline
                                                   label={"Product Article"}
                                                   onChange={(event) => setProductArticle(event.target.value)}
                                                   focused/>


                                    </Box>
                                </ThemeProvider>

                            <p>FAQ</p>
                            <div className={"addIconSort"}>
                                <p style={{paddingLeft: "2ch"}}>Add FAQ Item </p>
                                <AddIcon onClick={addNewFAQ} className={"icon"}/>
                            </div>
                            <div className={"BoxScrollContainer"} style={{maxHeight: "500px"}}>
                                <div className="vSort">
                                    {Object.entries(faqDict).map(([name, features], index) => (
                                        <div className={"vSort"} style={{
                                            marginBottom: "2ch"
                                        }}>
                                            <div className={"hSort"}>
                                                <ThemeProvider theme={myTheme}>
                                                    <Box
                                                        component="form"
                                                        sx={{
                                                            '& > :not(style)': {
                                                                m: 1,
                                                                width: '22ch',
                                                                color: "white",
                                                                backgroundColor: myTheme.palette.background.main,
                                                            },
                                                            '& input': {
                                                                color: 'white', // Set text color to white
                                                            },
                                                            '& .MuiInputBase-inputMultiline': {
                                                                color: 'white', // Set text color to white for multiline input
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                },
                                                            },
                                                            maxHeight: '150px',
                                                            overflow: 'auto'

                                                        }}
                                                        noValidate
                                                        autoComplete="off">
                                                        <TextField
                                                            variant={"outlined"}
                                                            color={"primary"}
                                                            value={name}
                                                            label={"Question"}
                                                            placeholder={"Enter Question"}
                                                            onChange={(event) => handleQuestionChange(event, index)}
                                                            focused/>

                                                    </Box>
                                                </ThemeProvider>
                                                <div className={"addIconSort"}>
                                                    <CloseIcon
                                                        onClick={() => handleDeleteFaq(name)}
                                                        className={"icon"}/>
                                                </div>
                                            </div>
                                            <ThemeProvider theme={myTheme}>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': {
                                                            m: 1,
                                                            width: '25ch',
                                                            color: "white",
                                                            backgroundColor: myTheme.palette.background.main,
                                                        },
                                                        '& input': {
                                                            color: 'white', // Set text color to white
                                                        },
                                                        '& .MuiInputBase-inputMultiline': {
                                                            color: 'white', // Set text color to white for multiline input
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                            },
                                                        },
                                                        maxHeight: '150px',
                                                        overflow: 'auto'
                                                    }}
                                                    noValidate
                                                    autoComplete="off">
                                                    <TextField variant={"outlined"}
                                                               color={"primary"}
                                                               value={features}
                                                               multiline
                                                               label={"Answer"}
                                                               placeholder={"Enter Answer"}
                                                               onChange={(event) => handleSetQAnswer(event, name)}
                                                               focused/>
                                                </Box>
                                            </ThemeProvider>

                                        </div>
                                    ))}

                                </div>

                            </div>

                        </div>

                    </div>
                )}
            </div>
        </div>
    );
}

export default SEO;