import React, {useEffect, useState} from 'react';
import "../data/App.css"
import axios from "axios";
import TextFieldComp from "../TextFieldComponent";
import SelectComp from "../SelectComponent";
import baseLink from "../data/api-base-link";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {IconButton} from "@mui/material";
import SwitchComp from "../SwitchComp";
import ToolTipNoIcon from "../utils/ToolTipNoIcon";
import Popup from "../popup";


function ManageOrders({fingerPrint, token, keyData, setError, setSuccess, userData, isAdmin, apiOrders}) {
    // rendering logic for middle section where you select a game based off the product

    const [searchKey, setSearchKey] = useState("")
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedKey, setSelectedKey] = useState(null)
    const [keyMenuItems, setKeyMenuItems] = useState({})
    const [keyPopup, setKeyPopup] = useState(false)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageDict, setPageDict] = useState({})
    const [selectedPage, setSelectedPage] = useState(1)
    const [pageNums, setPageNumDict] = useState({})
    const [isOn, setIsOn] = useState(false)

    useEffect(() => {
        let ordersList = [];

        // Flatten the keyData to create a list of all orders
        for (const user in keyData) {
            for (const order in keyData[user]) {
                ordersList.push({
                    userId: user,
                    orderId: order,
                    orderDetails: keyData[user][order]
                });
            }
        }

        // Sort ordersList by date (assuming orderDetails.date is a Date object or ISO string)
        ordersList.sort((a, b) => new Date(b.orderDetails.date) - new Date(a.orderDetails.date));

        // Total length of orders
        let totallen = ordersList.length;

        let newPageDict = {};
        let pageNum = 1;
        let pageNumDict = {};

        // Paginate the orders
        for (let i = 0; i < totallen; i += itemsPerPage) {
            newPageDict[pageNum] = ordersList.slice(i, i + itemsPerPage);
            pageNumDict[pageNum] = pageNum;
            pageNum++;
        }

        setPageNumDict(pageNumDict);
        setPageDict(newPageDict);
    }, [itemsPerPage, selectedPage, keyData]);


    const formatOrderDate = (timestamp) => {
        const now = new Date();
        const orderDate = new Date(timestamp);

        const timeDiff = now - orderDate; // Time difference in milliseconds
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Days difference

        const options = { hour: '2-digit', minute: '2-digit' }; // For formatting time
        const formattedTime = orderDate.toLocaleTimeString([], options);

        if (daysDiff < 1) {
            return `Today, ${formattedTime}`;
        } else if (daysDiff === 1) {
            return `Yesterday, ${formattedTime}`;
        } else if (daysDiff < 7) {
            return `${daysDiff} Days Ago, ${formattedTime}`;
        } else {
            const weeksDiff = Math.floor(daysDiff / 7);
            const formattedDate = orderDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
            return `${weeksDiff} Week${weeksDiff > 1 ? ("s") : ("")} Ago, ${formattedDate}`;
        }
    };



    const handleSetOrderType = (event) => {
        setSelectedKey("")
        setIsOn(event.target.checked);
        setSelectedOrder("");
        console.log(apiOrders)
    }

    const handleSearchKey = (event) => {
        const searchKey = event.target.value.toLowerCase();
        setSearchKey(event.target.value);
        setSelectedPage(1);


        let ordersList = [];

        // Flatten the keyData to create a list of all orders
        for (const user in keyData) {
            for (const order in keyData[user]) {
                // Add order if it matches the search key
                if (order.toLowerCase().includes(searchKey)) {
                    ordersList.push({
                        userId: user,
                        orderId: order,
                        orderDetails: keyData[user][order]
                    });
                }
            }
        }

        // Sort the filtered orders by date
        ordersList.sort((a, b) => new Date(b.orderDetails.date) - new Date(a.orderDetails.date));

        // Total length of filtered orders
        let totallen = ordersList.length;

        let newPageDict = {};
        let pageNum = 1;
        let pageNumDict = {};

        // Paginate the filtered orders
        for (let i = 0; i < totallen; i += itemsPerPage) {
            newPageDict[pageNum] = ordersList.slice(i, i + itemsPerPage);
            pageNumDict[pageNum] = pageNum;
            pageNum++;
        }

        setPageNumDict(pageNumDict);
        setPageDict(newPageDict);
    };




    const handleSetSelectedKey = (event) => {
        setSelectedKey(event.target.value.split(" ")[0])
    }


    const handleHWIDReset = async () => {
        try {
            console.log(selectedKey)
            if (selectedKey === "") {
                setError("Must select a Key for HWID Reset")
                return
            }
            const response = await axios.get(`https://${baseLink}/key/reset/` + selectedKey + "/" + "HWID Reset" + "/" + token + "?nocache=");
            if (response.data) {
                if (!(response.data.startsWith("Key not found"))) {
                }
                setSuccess(response.data)
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Invalid username or password.");
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                } else if (error.response.status === 400) {
                    setError(error.response.data);
                } else {
                    setError("An unexpected error occurred.");
                }
            }
        }
    };

    const handleKeyFreeze = async () => {
        try {
            if (selectedKey === "") {
                setError("Must select a key for HWID reset")
                return

            }
            const response = await axios.get(`https://${baseLink}/key/freeze/` + selectedKey + "/" + "Panel Freeze" + "/" + token + "?nocache=");
            if (response.data) {
                if (!(response.data.startsWith("Key not found"))) {
                }
                setSuccess(response.data);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Invalid username or password.");
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                }
            } else {
                setError("An unexpected error occurred.");
            }
        }
    };




    const handleCopy = (text, message) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setSuccess(message || 'Copied to clipboard');
                // You can add a success message or other feedback here if needed
            })
            .catch((err) => {
                setError('Failed to copy to clipboard');
            });
    };

    const handleSetSelectedOrder = (userid, orderid) => {
        setSelectedOrder(orderid)
        setSelectedUser(userid);
        setSelectedKey("")
        let tempDict = {}
        if (userid) {
            for (const [length, keyList] of Object.entries(keyData[userid][orderid].keys)) {
                for (let pKey in keyList) {
                    tempDict[`${keyList[pKey]} (${length})`] = keyList[pKey]
                }
            }
        }
        else {
            for (const [product, lenDict] of Object.entries(apiOrders[orderid].keys)) {

                for (let [length, keyList] of Object.entries(lenDict)) {
                    for (let pKey in keyList) {
                        tempDict[`${keyList[pKey]} (${product}, ${length})`] = keyList[pKey]
                    }
                }
            }
        }

        setKeyMenuItems(tempDict);


    }

    const viewAllApiOrderKeys = () =>  {

        if (apiOrders[selectedOrder].keys.length === 0) {
            setError("This order has no keys.")
            return
        }
        setKeyPopup(!keyPopup)

    }

    const viewAllKeys = () => {
        if ((keyData[selectedUser][selectedOrder].keys).length === 0) {
            setError("This order has no keys.")
        }
        else {
            setKeyPopup(!keyPopup);
        }
    }




    const handleSetPageNum = (event) => {
        setSelectedPage(event.target.value)
        setSelectedOrder("")
        setSelectedKey("")
    }


    const handleSetItemsPerPage = (event) => {
        const value = parseInt(event.target.value, 10);
        setSelectedPage(1);
        setSelectedOrder("");
        setSelectedUser("");

        if (isNaN(value) || value <= 0) {
            setItemsPerPage(1); // Default to 1 if the value is not a valid non-zero integer
        } else {
            setItemsPerPage(value);
        }
    };
    return (
        <div className="PageDefaults">
            <div className={"BoxItemFlexBox"}>
                <div className="KeyManage">
                    <div className={"InputKey"}>
                        <div className={"BoxItem keyMenu"}>
                            <div className={"keySearch"}>
                                <p>Search for Order</p>
                                <TextFieldComp label={"Search"} type={"text"} onChangeFunc={handleSearchKey}/>

                                    {isAdmin ? (
                                        <div className={"hSort"}>
                                            <SelectComp label={"Page"} onChangeFunc={handleSetPageNum} menuItems={pageNums}
                                                        selectWidth={"7ch"} value={selectedPage}/>
                                            <TextFieldComp label={"Items"} type={"number"}
                                                       onChangeFunc={handleSetItemsPerPage} boxWidth={"6ch"}
                                                       value={itemsPerPage}/>
                                            <div style={{marginTop: "3ch"}}>
                                                <SwitchComp isOn={isOn} offText={"Sellers"} onText={"API"} handleChange={handleSetOrderType} />
                                            </div>
                                        </div>
                                    ): (
                                        <div className={"hSort"}>
                                            <SelectComp label={"Page #"} onChangeFunc={handleSetPageNum}
                                                        menuItems={pageNums}
                                                        selectWidth={"11ch"} value={selectedPage}/>
                                            <TextFieldComp label={"Items"} type={"number"}
                                                           onChangeFunc={handleSetItemsPerPage} boxWidth={"11ch"}
                                                           value={itemsPerPage}/>
                                        </div>
                                    )}


                            </div>
                            <Popup size={'large'} isOpen={keyPopup}
                                   toggleOpen={isOn ? (viewAllApiOrderKeys) : (viewAllKeys)}>
                                <div className={"hSort"}>
                                    <div className="vSort">
                                        <h2>Delivered Keys for <em>{selectedOrder}</em></h2>
                                        {isOn ? (
                                            <>
                                                {(!selectedOrder === "") && Object.entries((apiOrders[selectedOrder].keys)).map(([product, keyDict]) => (

                                                    <div key={product}><p><em>{product}</em></p>

                                                        {Object.entries(keyDict).map(([length, keyList]) => (
                                                            <div key={length}>
                                                                <p style={{marginRight: '8px'}}>
                                                                    <em><IconButton className="copyButton"
                                                                                    onClick={() => handleCopy(keyList.join('\n'), `Copied all ${length} keys to clipboard`)}
                                                                                    size={"small"}>
                                                                        <ContentCopyIcon sx={{
                                                                            fontSize: 20,
                                                                            color: "white",
                                                                            backgroundColor: "transparent"
                                                                        }}/>
                                                                    </IconButton>{length}:<br/></em>
                                                                    {keyList.map((sKey) => (

                                                                        <div key={sKey}>
                                                                            {sKey}
                                                                            <IconButton className="copyButton"
                                                                                        onClick={() => handleCopy(sKey)}
                                                                                        size={"small"}>
                                                                                <ContentCopyIcon sx={{
                                                                                    fontSize: 20,
                                                                                    color: "white",
                                                                                    backgroundColor: "transparent"
                                                                                }}/>
                                                                            </IconButton><br/>
                                                                        </div>
                                                                    ))}
                                                                </p>

                                                            </div>
                                                        ))}

                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {selectedUser && selectedOrder && Object.entries((keyData[selectedUser][selectedOrder].keys)).map(([length, keyList]) => (
                                                    <div key={length} style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <p style={{marginRight: '8px'}}>
                                                            <em><IconButton className="copyButton"
                                                                            onClick={() => handleCopy(keyList.join('\n'), `Copied all ${length} keys to clipboard`)}
                                                                            size={"small"}>
                                                                <ContentCopyIcon sx={{
                                                                    fontSize: 20,
                                                                    color: "white",
                                                                    backgroundColor: "transparent"
                                                                }}/>
                                                            </IconButton>{length}:<br/></em>
                                                            {keyList.map((sKey) => (

                                                                <div key={sKey}>
                                                                    {sKey}
                                                                    <IconButton className="copyButton"
                                                                                onClick={() => handleCopy(sKey)}
                                                                                size={"small"}>
                                                                        <ContentCopyIcon sx={{
                                                                            fontSize: 20,
                                                                            color: "white",
                                                                            backgroundColor: "transparent"
                                                                        }}/>
                                                                    </IconButton><br/>
                                                                </div>
                                                            ))}
                                                        </p>

                                                    </div>
                                                ))}
                                            </>)}

                                    </div>
                                </div>
                            </Popup>

                            <>
                                {selectedOrder ? (
                                    <>
                                        <div className={"keySearch"}>
                                            <p>Select Key</p>
                                            <SelectComp onChangeFunc={handleSetSelectedKey} label={"Select a Key"}
                                                        menuItems={keyMenuItems}/>
                                            {selectedOrder && (
                                                <div>
                                                    <button onClick={isOn ? (viewAllApiOrderKeys) : (viewAllKeys)}>
                                                        View All Keys of Order
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <p>Please select an order from the list below.</p>
                                )}
                            </>
                            <div style={{margin: "0 2ch 0 2ch"}}>
                                {selectedKey ? (
                                    <div className={"keyActions"}>
                                        <p>Perform Key Actions</p>
                                        <p style={{marginLeft: "1ch"}}><em>Key: <br/></em>{selectedKey}</p>
                                        <div className={"buttons"}>
                                            <div>
                                                <button onClick={handleHWIDReset}>HWID Reset</button>
                                            </div>
                                            <div>
                                                <button onClick={handleKeyFreeze}>Freeze Key</button>
                                            </div>
                                        </div>

                                    </div>
                                ) : (
                                    <p>Please select a key to perform actions.</p>
                                )}
                            </div>
                        </div>

                        <div className={"KeyScrollContainer"}>
                            {!isOn ? (
                                <>
                                    {pageDict && !(Object.keys(pageDict).length === 0) && selectedPage && pageDict[selectedPage].map((order, index) => (
                                        <>
                                            {order.orderDetails && !order.orderDetails.error && (
                                                <div
                                                    className={selectedOrder && selectedOrder.startsWith(order.orderId) ? ("BoxItem keyBox active") : ("BoxItem keyBox")}
                                                    onClick={() => handleSetSelectedOrder(order.userId, order.orderId)}>

                                                    <div className="topFlex">
                                                        <div className="orderID">
                                                            <h2 className="keyDeco">
                                                                <em>ID: </em>{order.orderId}
                                                                <IconButton
                                                                    className="copyButton"
                                                                    onClick={() => handleCopy(order.orderId, "Order ID copied to clipboard")}
                                                                    size="small"
                                                                >
                                                                    <ContentCopyIcon
                                                                        sx={{
                                                                            fontSize: 20,
                                                                            color: "white",
                                                                            backgroundColor: "transparent",
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </h2>
                                                        </div>
                                                        <div className="orderDate">
                                                            <ToolTipNoIcon tooltipText={order.orderDetails.date}>
                                                                <p className={"keyDeco"}>{formatOrderDate(order.orderDetails.date)}
                                                                    <IconButton className="copyButton"
                                                                                onClick={() => handleCopy(order.orderDetails.date, "Timestamp copied to clipboard")}
                                                                                size={"small"}>
                                                                        <ContentCopyIcon sx={{
                                                                            fontSize: 20,
                                                                            color: "white",
                                                                            backgroundColor: "transparent"
                                                                        }}/>
                                                                    </IconButton></p>
                                                            </ToolTipNoIcon>
                                                        </div>
                                                    </div>
                                                    <div className={"orderDetails"}>
                                                        {isAdmin && (
                                                            <>

                                                                <div>
                                                                    <p className={"keyDeco"}><em>User: </em>

                                                                        {userData[order.userId].avatar && (
                                                                            <img src={userData[order.userId].avatar}
                                                                                 alt={"asd"}
                                                                                 loading="lazy"/>)

                                                                        }

                                                                        {(userData[order.userId].dusername !== "N/A" && userData[order.userId].dusername) || order.userId}
                                                                        <IconButton className="copyButton"
                                                                                    onClick={() => handleCopy(order.userId, "User ID copied to clipboard")}
                                                                                    size={"small"}>
                                                                            <ContentCopyIcon sx={{
                                                                                fontSize: 20,
                                                                                color: "white",
                                                                                backgroundColor: "transparent"
                                                                            }}/>
                                                                        </IconButton>
                                                                    </p>
                                                                </div>

                                                            </>
                                                        )}


                                                        <div>
                                                            <p className={"keyDeco"}>
                                                                <em>Total:</em> $ {order.orderDetails.total}
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <p className={"keyDeco"}>
                                                                <em>Product:</em> {order.orderDetails.product}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className={"keyDeco"}>
                                                                <em>Payment
                                                                    Method:</em> {order.orderDetails.payment_method === "sellerapi" ? ("Seller API") : (order.orderDetails.payment_method === "api" ? (order.orderDetails.payment_method.toUpperCase()) : (order.orderDetails.payment_method.toUpperCase().substring(0, 1) + order.orderDetails.payment_method.substring(1, order.orderDetails.payment_method.length)))}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}

                                        </>

                                    ))}
                                </>
                            ) : (
                                <>

                                {apiOrders && Object.entries(apiOrders)
                                        .sort(([, a], [, b]) => new Date(b.date) - new Date(a.date))
                                        .map(([orderid, orderdata]) => (
                                        <>

                                            <div
                                                className={selectedOrder && selectedOrder.startsWith(orderid) ? ("BoxItem keyBox active") : ("BoxItem keyBox")}
                                                onClick={() => handleSetSelectedOrder(false, orderid)}>
                                                <div className="topFlex">
                                                    <div className="orderID">
                                                        <h2 className="keyDeco">
                                                            <em>ID:</em> {orderid}
                                                            <IconButton
                                                                className="copyButton"
                                                                onClick={() => handleCopy(orderid, "Order ID copied to clipboard")}
                                                                size="small"
                                                            >
                                                                <ContentCopyIcon
                                                                    sx={{
                                                                        fontSize: 20,
                                                                        color: "white",
                                                                        backgroundColor: "transparent",
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </h2>
                                                    </div>
                                                    <div className="orderDate">
                                                            <ToolTipNoIcon tooltipText={orderdata.date}>
                                                                <p className={"keyDeco"}>{formatOrderDate(orderdata.date)}
                                                                    <IconButton className="copyButton"
                                                                                onClick={() => handleCopy(orderdata.date, "Timestamp copied to clipboard")}
                                                                                size={"small"}>
                                                                        <ContentCopyIcon sx={{
                                                                            fontSize: 20,
                                                                            color: "white",
                                                                            backgroundColor: "transparent"
                                                                        }}/>
                                                                    </IconButton></p>
                                                            </ToolTipNoIcon>
                                                    </div>
                                                </div>
                                                <div className={"orderDetails"}>
                                                    <div>
                                                        <p className={"keyDeco"}>
                                                            <em>Total:</em> $ {orderdata.total}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className={"keyDeco"}>
                                                            <em>Products:</em> {Object.keys(orderdata.keys).map((key, index, array) => (
                                                            <>
                                                                {key}
                                                                {index < array.length - 1 && ', '}
                                                            </>
                                                        ))}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}

                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManageOrders;