import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import axios from "axios";
import myTheme from "../data/mui-theme";
import baseLink from "../data/api-base-link";
import getFingerprint from "../utils/getFP";
import discordInvite from "../data/discord-invite";


function Login({ setPaymentMethods, setFingerprint, setOrderData, setApiOrders, setAPIKeyData, setToken, setError, setBalance, setSuccess, setDiscount, setIsAdmin, setProductData, setUserData, setCategories}) {
    const [usernameValue, setUsernameValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");



    const loginWithToken = async (token) => {
        // Your login logic here
        setSuccess("Logging in...")
        try {
            const response = await axios.post(`https://${baseLink}/user/relogin`, {
                token: token},
                {timeout: 20000});
            if (response.data[0]) {
                console.log(response.data[0].paymentmethods)
                setDiscount(parseFloat(response.data[0].discount));
                setBalance(response.data[0].balance)
                setToken(token);
                setIsAdmin(response.data[0].secure)
                setProductData(response.data[0].productdata)
                setUserData(response.data[0].userdata)
                setAPIKeyData(response.data[0].apikeys)
                setOrderData(response.data[0].orders)
                setApiOrders(response.data[0].apiorders)
                setCategories(response.data[0].categories)
                setPaymentMethods(response.data[0].paymentmethods)
                let fingerPrint = await getFingerprint()
                setFingerprint(fingerPrint)
                setSuccess("Login Successful");
            }
        }
        catch(error) {
            console.log(error);
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Token expired, please login again.");
                    localStorage.removeItem("loginToken")
                    setToken(null);

                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                }
                else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                }
                else if (error.response.status === 400) {
                    setError(error.response.data);
                }
                else {
                    setError("An unexpected error occurred")
                }
            } else {
                setError("An unexpected error occurred.");
            }
        }

    };

    useEffect(() => {
        const token = localStorage.getItem('loginToken');
        if (token) {
            loginWithToken(token);
        }
    }, []); // Empty dependency array ensures this runs only once


    const handleUsernameChange = (event) => {
        setUsernameValue(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPasswordValue(event.target.value);
    };



    const handleLogin = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        try {
            if (usernameValue === "" || passwordValue === "") {
                setError("Please enter a username and password.");
                return;
            }
            setSuccess("Logging in...")
            const response = await axios.post(`https://${baseLink}/user/login`,
                {username:usernameValue,
                    password:passwordValue},
                {timeout: 20000});
            if (response.data[0]) {
                setDiscount(parseFloat(response.data[0].discount));
                setBalance(response.data[0].balance)
                localStorage.setItem('loginToken', response.data[0].token);
                setToken(response.data[0].token);
                setIsAdmin(response.data[0].secure)
                setProductData(response.data[0].productdata)
                setUserData(response.data[0].userdata)
                setSuccess("Login Successful");
                setOrderData(response.data[0].orders)
                setAPIKeyData(response.data[0].apikeys)
                setCategories(response.data[0].categories)
                let fingerPrint = await getFingerprint()
                setFingerprint(fingerPrint)
                setPaymentMethods(response.data[0].paymentmethods)
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data)
            }
            // Handle errors
        }
    };


    return (
        <div className={"PageDefaults"}>

            <div className={"login"}>
                <form autoComplete="on" onSubmit={handleLogin}>
                    <div className={"BoxItem"} style={{alignItems: "center"}}>
                        <div className={"loginitem"}>
                            <ThemeProvider theme={myTheme}>
                                <Box
                                    component="div"
                                    sx={{

                                        '& > :not(style)': {
                                            m: 1,
                                            width: '400px',
                                            color: "white",
                                            backgroundColor: myTheme.palette.background.main,
                                        },
                                        '& input': {
                                            color: 'white', // Set text color to white
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                            },
                                            '&:hover fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                            },
                                        },
                                    }}
                                >
                                    <TextField
                                        placeholder="Username"
                                        name="username"
                                        value={usernameValue}
                                        onChange={handleUsernameChange}
                                        color="primary"
                                        variant="outlined"
                                        focused
                                    />
                                </Box>
                            </ThemeProvider>
                        </div>
                        <div className={"loginitem"}>
                            <ThemeProvider theme={myTheme}>
                                <Box
                                    component="div"
                                    sx={{

                                        '& > :not(style)': {
                                            m: 1,
                                            width: '400px',
                                            color: "white",
                                            backgroundColor: myTheme.palette.background.main,
                                        },
                                        '& input': {
                                            color: 'white', // Set text color to white
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                            },
                                            '&:hover fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                            },
                                        },
                                    }}
                                >
                                    <TextField
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={passwordValue}
                                        onChange={handlePasswordChange}
                                        color="primary"
                                        variant="outlined"
                                        focused
                                    />
                                </Box>
                            </ThemeProvider>

                        </div>
                        <div className={"loginitem"}>
                            <button  type="submit">
                                Login

                            </button>
                            <button onClick={() => window.open(discordInvite, "_blank", "noopener,noreferrer")}>
                                Register
                            </button>

                        </div>


                    </div>
                </form>


            </div>


        </div>
    );
}

export default Login;
