import {createTheme} from "@mui/material/styles";

const myTheme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif", // Change to your desired font
        fontWeight: 700,
    },
    palette: {
        primary: {
            main: "#5f71f6",
        },
        secondary: {
            main: '#FFF',
        },
        background: {
            main: "#121212",
        },
    },
});

export default myTheme;



//import {createTheme} from "@mui/material/styles";
//
// const myTheme = createTheme({
//     palette: {
//         primary: {
//             main: "#5f71f6",
//         },
//         secondary: {
//             main: '#FFF',
//         },
//         background: {
//             main: "#121212",
//         },
//     },
// });
//
// export default myTheme;