import './components/data/App.css';
import React, {useState, useEffect } from 'react';
import Sidebar from "./components/Sidebar"
import BuyKeys from "./components/pages/buykeys"
import Login from "./components/pages/login"
import Logout from './components/pages/logout'
import Settings from "./components/pages/settings"
import ErrorMessage from "./components/errorBox"
import SuccessMessage from "./components/msgBox";
import ProductSettings from "./components/pages/productsettings";
import UserSettings from "./components/pages/usersettings";
import ManageKeys from "./components/pages/managekeys"
import ManageOrders from "./components/pages/manageorders"
import SEO from "./components/pages/seo"
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";




function App() {
    const [gameData, setGameData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const [token, setToken] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [error, setError] = useState("")
    const [successMsg, setSuccessMsg] = useState("")
    const [isAdmin, setIsAdmin] = useState(null)
    const [balance, setBalance] = useState(0)
    const [isOpen, setIsOpen] = useState(false);
    const [apiKeyData, setAPIKeyData]=  useState(null);
    const [errorClass, setErrorClass] = useState('');
    const [successClass, setSuccessClass] = useState('');
    const [apiOrders, setApiOrders] = useState(null);
    const [categories, setCategories] = useState(null);
    const [fingerPrint, setFingerprint] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(null)
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    // error message
    useEffect(() => {
        if (error) {
            setErrorClass('enter');
            const timer = setTimeout(() => {
                setErrorClass('exit');
                const removeTimer = setTimeout(() => setError(''), 300); // Delay to allow exit animation
                return () => clearTimeout(removeTimer);
            }, 5000); // Display for 5 seconds
            return () => clearTimeout(timer);
        }
    }, [error]);

    useEffect(() => {
        if (successMsg) {
            setSuccessClass('enter');
            const timer = setTimeout(() => {
                setSuccessClass('exit');
                const removeTimer = setTimeout(() => setSuccessMsg(''), 300); // Delay to allow exit animation
                return () => clearTimeout(removeTimer);
            }, 5000); // Display for 5 seconds
            return () => clearTimeout(timer);
        }
    }, [successMsg]);




    if(!token) {
        return (<div >
                <div className={"notifyBox"}>
                {error && <ErrorMessage className={errorClass} message={error} />}
                {successMsg && <SuccessMessage className={successClass} message={successMsg} />}
                </div>
                <Login setPaymentMethods={setPaymentMethods} setFingerprint={setFingerprint} setCategories={setCategories} setApiOrders={setApiOrders} setOrderData={setOrderData} setAPIKeyData={setAPIKeyData} setUserData={setUserData} setProductData={setGameData} setIsAdmin={setIsAdmin} setDiscount={setDiscount} setToken={setToken} setBalance={setBalance} setError={setError} setSuccess={setSuccessMsg}/>
            </div>
        )
    }
    return (
        <Router>
            <div className={""}>

                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <div className="App">
                    <div>
                        <button className="hamburger" onClick={toggleSidebar}>
                            ☰
                        </button>
                    </div>
                    <Sidebar setIsOpen={setIsOpen} isAdmin={isAdmin} isOpen={isOpen} setError={setError} setSuccess={setSuccessMsg} token={token}/>
                    <Routes>
                        <Route path="/buykeys"
                               element={<BuyKeys paymentMethods={paymentMethods} setToken={setToken} fingerPrint={fingerPrint} setBalance={setBalance} setDiscount={setDiscount} discount={discount} balance={balance} gamedata={gameData} token={token}
                                                 setError={setError} setSuccess={setSuccessMsg} orderData={orderData}/>}></Route>
                        <Route path="/settings"
                               element={<Settings paymentMethods={paymentMethods} fingerPrint={fingerPrint} isAdmin={isAdmin} token={token} setError={setError}
                                                  setSuccess={setSuccessMsg}/>}></Route>
                        <Route path="/logout" element={<Logout fingerPrint={fingerPrint} token={token} setToken={setToken} setError={setError}
                                                               setSuccess={setSuccessMsg}/>}></Route>
                        <Route path="/manageorders" element={<ManageOrders fingerPrint={fingerPrint} apiOrders={apiOrders} isAdmin={isAdmin} userData={userData} token={token} setToken={setToken} setError={setError}
                                                               setSuccess={setSuccessMsg} keyData={orderData}/>}></Route>
                        <Route path={"/managekeys"}
                               element={<ManageKeys isAdmin={isAdmin} userData={userData} token={token} setToken={setToken} setError={setError}
                                                    setSuccess={setSuccessMsg} keyData={orderData}/>} />

                        {isAdmin && (
                            <>
                                <Route path={"/seo"}
                                       element={<SEO fingerPrint={fingerPrint} setCategories={setCategories} categoryData={categories} isAdmin={isAdmin} token={token} setToken={setToken} setGameData={setGameData} productData={gameData} setError={setError}
                                                            setSuccess={setSuccessMsg} keyData={orderData}/>} />
                                <Route path="/productsettings"
                                       element={<ProductSettings fingerPrint={fingerPrint} categoryItems={categories} apiKeyData={apiKeyData} setGameData={setGameData} menuItems={gameData} token={token} setError={setError}
                                                                 setSuccess={setSuccessMsg}/>}></Route>
                                <Route path="/usersettings"
                                       element={<UserSettings  fingerPrint={fingerPrint} setUserData={setUserData} token={token} setError={setError} userInfo={userData}
                                                              setSuccess={setSuccessMsg}/>}></Route>
                            </>
                        )}

                    </Routes>

                </div>
                <div className={"notifyBox"}>
                    {error && <ErrorMessage className={errorClass} message={error} />}
                    {successMsg && <SuccessMessage className={successClass} message={successMsg} />}
                </div>
            </div>
        </Router>
    );
}
export default App;
