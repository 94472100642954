import React, {useEffect, useRef, useState} from 'react';
import "../data/App.css"
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import axios from "axios";
import TextFieldComp from "../TextFieldComponent";
import SelectComp from "../SelectComponent";
import CheckboxComp from "../CheckboxComp";
import { IconButton } from '@mui/material'; // Import IconButton from MUI
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import baseLink from "../data/api-base-link";
import myTheme from "../data/mui-theme";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SwitchComp from "../SwitchComp";
import Popup from "../popup";
import ToolTip from "../ToolTip";
import GppGoodIcon from "@mui/icons-material/GppGood";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import UpdateIcon from "@mui/icons-material/Update";
import ErrorIcon from "@mui/icons-material/Error";
export const setLocalApiKey = (apiKey) => {
    localStorage.setItem('apiKey', apiKey);
};

export const getLocalApiKey = () => {
    return localStorage.getItem('apiKey');
};



function ProductSettings({fingerPrint, token, setGameData, setError, setSuccess, menuItems, apiKeyData, categoryItems}) {


    // rendering logic for middle section where you select a game based off the product
    const historyListRef = useRef(null);
    const [activeRow, setActiveRow] = useState(null);
    const [product, setProduct] = useState("")
    const [pID, setId] = useState("")
    const [amountDict, setAmountDict] = useState({})
    const [keyDict, setKeyDict] = useState({})
    const [keyCount, setKeyCount] = useState({})
    const [isStockedItem, setStockedItem] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [newProductID, setNewProductID] = useState(null)
    const [newProductName, setNewProductName] = useState("")
    const [newProductLengths, setNewProductLengths] = useState({})
    const [newLength, setNewLength] = useState(0)
    const [newPrice, setNewPrice] = useState(0)
    const [pName, setName] = useState("")
    const [status, setStatus] = useState("")
    const [sellerVisibility, setSellerVisibility] = useState(false)
    const [apiKey, setApiKey] = useState(getLocalApiKey())
    const [isApiPopupOpen, setIsApiPopupOpen] = useState(false)
    const [isBundleApiPopupOpen, setIsBundleApiPopupOpen] = useState(false)
    const [apiLinkDict, setApiLinkDict] = useState(false)
    const [tempApiKey, setTempApiKey] = useState("")
    const [selectedEmoji, setSelectedEmoji] = useState(0);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [bundleProductOne, setBundleProductOne] = useState("");
    const [bundleProductTwo, setBundleProductTwo] = useState("");
    const [docs, setDocs] = useState("")
    const [loader, setLoader] = useState("")
    const [useCustomPricing, setUseCustomPricing] = useState(false)
    const [featurePopup, setFeaturePopup] = useState(false)
    const [featureDict, setFeatureDict] = useState({})
    const [productImageLinks, setProductImageLinks] = useState("")
    const [productVideoLinks, setProductVideoLinks] = useState("")
    const [newProductIcon, setNewProductIcon] = useState("")
    const [newProductImages, setNewProductImages] = useState("")
    const [newProductVideos, setNewProductVideos] = useState("")
    const [icon, setIcon] = useState("")
    const [productLink, setProductLink] = useState("")
    const [isOn, setIsOn] = useState(false)
    const [loopItem, setLoopItem] = useState(menuItems)
    const [isFeatured, setIsFeatured] = useState(false)
    const [pricingPopup, setPricingPopup] = useState(false)
    const [customPricingDict, setCustomPricingDict] = useState({})
    const [selectedPriceLength, setSelectedPriceLength] = useState(null)
    const [categorySettings, setCategorySettings] = useState({})
    const emojis = {
        0:{"color":"lime", "icon":GppGoodIcon},
        1:{"color":"yellow", "icon":ReportProblemIcon},
        2:{"color":"orange", "icon":ReportProblemIcon},
        3:{"color":"blue", "icon":UpdateIcon},
        4:{"color":"red", "icon":ErrorIcon}
    }


    const handleSetEditType = (event) => {
        setProduct("")
        if (event.target.checked) {
            setLoopItem(categoryItems)
        }
        else {
            setLoopItem(menuItems)
        }
        setIsOn(event.target.checked);
        setActiveRow(null)
    }

    useEffect(() => {
        const handleScroll = () => {
            const list = historyListRef.current;
            if (list) {
                const rows = list.querySelectorAll('.row');
                rows.forEach(row => {
                    const rect = row.getBoundingClientRect();
                    const listRect = list.getBoundingClientRect();

                    // Calculate visible height within the list viewport
                    const visibleHeight = Math.min(rect.bottom, listRect.bottom) - Math.max(rect.top, listRect.top);

                    // Ensure the visible height is within valid bounds
                    const clampedVisibleHeight = Math.max(0, Math.min(rect.height, visibleHeight));

                    // Calculate visibility percentage
                    const visibilityPercentage = clampedVisibleHeight / rect.height;

                    // Apply styles based on visibility
                    if (visibilityPercentage > 0 && visibilityPercentage < 1) {
                        row.style.opacity = visibilityPercentage.toString();
                        row.style.transform = `scale(${visibilityPercentage})`;
                        row.style.visibility = 'visible';
                    } else if (visibilityPercentage <= 0) {
                        row.style.opacity = '0';
                        row.style.transform = 'scale(0)';
                        row.style.visibility = 'hidden'; // Hide the row completely
                    } else {
                        row.style.opacity = '1';
                        row.style.transform = 'scale(1)';
                        row.style.visibility = 'visible';
                    }
                });
            }
        };

        const list = historyListRef.current;
        if (list) {
            list.addEventListener('scroll', handleScroll);
            // Call handleScroll initially to set the correct state on load
            handleScroll();
        }

        return () => {
            if (list) {
                list.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    // add useEffect for retrieving userdata here, should only run once


    const handleLinkChange = (event) => {
        setProductLink(event.target.value)
    }

    const addNewFeature = (feature) => {
        let name = "New Features"
        let count = 1;
        while(true) {
            if (name in featureDict) {
                name = `New Features ${count}`
                count++;
            }

            else{
                break
            }
        }
        setFeatureDict(prevDict => ({
            ...prevDict,
            [name]: ""
        }));

    }



    const handleRowClick = (key) => {

        setActiveRow(key);
        setProductImageLinks(menuItems[key].images || "")
        setProductVideoLinks(menuItems[key].videos)
        setIcon(menuItems[key].logo)
        handleLoaderChange(menuItems[key].loader)
        handleDocsChange(menuItems[key].instructions)

        if (!isOn) {
            setProductEvent(key)
            setUseCustomPricing(menuItems[key].custompricing)
        }

    };



    const handleAddProduct = () => {
        if (isPopupOpen && menuItems && product && menuItems[product] && menuItems[product].visibility) {
            setSellerVisibility(menuItems[product].visibility)
        }

        else {
            setSellerVisibility(false)
        }
        setFeatureDict({})
        setIsPopupOpen(!isPopupOpen);
    };

    const handleRemoveApiKey = () => {
        setApiKey("")
        setLocalApiKey("")
        setApiLinkDict(false)
    }

    const handleCreateKey = async (type) => {
        try {
            const response = await axios.get(`https://${baseLink}/api/keys/${type}/${token}`);

            if (response.status === 200) {
                if (response.data) {
                    setApiLinkDict(response.data) // change this to add new key to api link dict
                }
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const handleToggleApiPopup = () => {
        setIsApiPopupOpen(!isApiPopupOpen)
        if (apiKey && !(apiKey === "")) {
            handleAPILinks()
        }
        setApiLinkDict(false)
    }

    const handleToggleBundleApiPopup = () => {
        setIsBundleApiPopupOpen(!isApiPopupOpen)
        if (apiKey && !(apiKey === "")) {
            handleAPILinks()
        }
        setApiLinkDict(false)
    }

    const handleAPILinks = async () => {
        setApiKey(tempApiKey)
        setLocalApiKey(apiKey); // set api key to local storage
        try {
            const response = await axios.get(`https://${baseLink}/api/links/${product}/${apiKey}/${token}`);

            if (response.status === 200) {
                if (response.data) {
                    setApiLinkDict(response.data)
                }
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data)
            } else {
                setError("Application Error")
            }
        }
    }
    const handleSetBundleProductOne = (event) => {
        setBundleProductOne(event.target.value)
    }
    const handleSetBundleProductTwo = (event) => {
        setBundleProductTwo(event.target.value)
    }

    const handleBundleAPILinks = async () => {
        setApiKey(tempApiKey)
        setLocalApiKey(apiKey); // set api key to local storage

        if (apiKey === "") {
            setError("Must enter an API key")
            return
        }

        if (bundleProductOne === "" || bundleProductTwo === "") {
            setError("Must select both products")
            return
        }
        try {
            const response = await axios.get(`https://${baseLink}/api/links/bundle/${bundleProductOne}/${bundleProductTwo}/${apiKey}/${token}`);

            if (response.status === 200) {
                if (response.data) {
                    setApiLinkDict(response.data)
                }
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data)
            } else {
                setError("Application Error")
            }
        }
    }

    const initKeyData = () => {
        let newKeyDict = {}
        for (const product in menuItems) {
            if (menuItems[product].stocked) {
                initKeyCount(product, menuItems[product].stock)
                newKeyDict[product] = menuItems[product].stock
            }
        }
        setKeyDict(newKeyDict)
    }

    const initKeyCount = (product, newKeyDict) => {
        const newKeyCount = {};
        for (const key in newKeyDict) {
            const nonEmptyLinesCount = newKeyDict[key].split("\n").filter(line => line.trim() !== "").length;
            newKeyCount[key] = nonEmptyLinesCount;
        }

        setKeyCount(prevKeyCount => ({
            ...prevKeyCount,
            [product]: newKeyCount
        }));
    };

    const updateKeyCount = (product, newKeyDict) => {
        const newKeyCount = {};
        for (const key in newKeyDict) {
            const nonEmptyLinesCount = newKeyDict[key].split("\n").filter(line => line.trim() !== "").length;
            newKeyCount[key] = nonEmptyLinesCount;
        }

        setKeyCount(prevKeyCount => ({
            ...prevKeyCount,
            [product]: newKeyCount
        }));
    };

    const handleSetKeyDict = (length, value) => {
        try {
            // Create a new amountDict with the updated value
           const newKeyDict = {
                ...keyDict,
                [product]: {
                    ...keyDict[product], // Preserve existing keys and values for the product
                    [length]: value.target.value // Use the length variable as the key
                }
            };

            // Update the state with the new amountDict
            setKeyDict(newKeyDict);
            updateKeyCount(product,newKeyDict[product]);

        }
        catch(error) {
            setError(error)
        }
    }



    const handleRestock = async () => {
        const stockDict= {"stock":keyDict[product]}
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/${product}/${token}`, stockDict);

            if (response.status === 200) {
                if (response.data) {
                    setSuccess(response.data.message)
                    setGameData(prevData => ({
                        ...prevData,
                        [product]: {
                            ...prevData[product], // Spread the existing product data
                            ...response.data.productData // Overwrite with the new data
                        }
                    }));
                }
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const handleEditCategorySettings = (key, value) => {
        setCategorySettings(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const saveCategorySettings = async () => {
        try{
            const response = await axios.post(`https://${baseLink}/settings/admin/categories/edit/${activeRow}/${token}`, categorySettings);
            if (response.status === 200) {
                setSuccess("Category Saved")
            }

        } catch(err) {
            if (err.response && err.response.data) {
                setError(err.response.data)
            }
            else {
                setError("An unexpected error occured")
            }
        }
    }

    const createNewProduct = async () => {

        if (!newProductName || !newProductID) {
            setError("Must enter a valid Product Name and ID")
            return
        }

        if (!newProductLengths) {
            setError("Must enter valid Product Lengths")
            return
        }



        const productDict = {name: newProductName, stocked: isStockedItem, id:newProductID, lengths: newProductLengths, visibility: sellerVisibility, features: featureDict, images: newProductImages, videos: newProductVideos, logo:newProductIcon};
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/new/${token}`, productDict);

            if (response.status === 200) {
                if (response.data) {
                    setSuccess(response.data.message)
                    setGameData(prevData => ({
                        ...prevData,
                        [newProductName]: response.data.productData
                    }));
                    const tempStockDict = {}
                    for (let length of Object.keys(newProductLengths)) {
                        tempStockDict[`${length} Day`] = ""
                    }
                    // update key dict manually because im lazy to make a general function
                    for (let length of Object.keys(tempStockDict)) {
                        try {
                            // Create a new amountDict with the updated value
                            const newKeyDict = {
                                ...keyDict,
                                [newProductName]: {
                                    ...keyDict[newProductName], // Preserve existing keys and values for the product
                                    [length]: "" // Use the length variable as the key
                                }
                            };

                            // Update the state with the new amountDict
                            setKeyDict(newKeyDict);
                            updateKeyCount(newProductName,newKeyDict[newProductName]);

                        }
                        catch(error) {
                            setError(error)
                        }
                    }




                    setIsPopupOpen(false)
                }
                // add new product info to dict here
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const handlePricingPopup = () => {
        setPricingPopup(!pricingPopup)
    }

    const handleDeleteProduct = async () => {
        try {
            const response = await axios.get(`https://${baseLink}/settings/admin/products/delete/` + product + "/" + token);
            if (response.status === 200) {
                setSuccess(response.data)
                try {
                    setGameData(prevData => {
                        const newData = {...prevData};
                        delete newData[product]; // Remove the key from the dictionary
                        return newData;
                    });
                }
                catch(newError) {

                }
                setActiveRow("")
                setProduct("")

            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const setProductPricing = async () => {

        const settingsDict = {}
        if (useCustomPricing) {
            settingsDict['custompricing'] = amountDict
        }

        else {
            settingsDict['prices'] = amountDict
        }

        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);
            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));


            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }


    }

    const setMediainfo = async() => {
        let settingsDict = {}

        if (!(icon === "")) {
            settingsDict['logo'] = icon
        }

        if (!(productImageLinks === "")) {
            settingsDict['images'] = productImageLinks
        }
        if (!(productVideoLinks === "")) {
            settingsDict['videos'] = productVideoLinks
        }

        console.log(settingsDict)
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const setStatusInfo = async () => {
        let settingsDict = {}

        settingsDict['featured'] = isFeatured

        if (!(status === "")) {
            settingsDict['status'] = status
        }
        const tempDict = {0: "green", 1: "yellow", 2:"orange", 3:"blue", 4:"red"}
        settingsDict['statuscolor'] = tempDict[selectedEmoji]

        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const setProductLinks = async() => {
        let settingsDict = {}
        if (!(loader === "")) {
            let wUrl = loader;
            if (!(wUrl.startsWith("http"))) {
                wUrl = "https://"+ wUrl
            }
            settingsDict["loader"] = wUrl
        }

        if (!(productLink === "")) {
            settingsDict['link'] = productLink
        }

        if (!(docs === "")) {
            let wUrl = docs;
            if (!(wUrl.startsWith("http"))) {
                wUrl = "https://"+ wUrl
            }
            settingsDict["instructions"] = wUrl
        }


        if (!(sellerVisibility === null)) {
            settingsDict['visibility'] = sellerVisibility
        }

        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const setProductInfo = async () => {
        let settingsDict = {}


        if (!(loader === "")) {
            let wUrl = loader;
            if (!(wUrl.startsWith("http"))) {
                wUrl = "https://"+ wUrl
            }
            settingsDict["loader"] = wUrl
        }

        if (!(productLink === "")) {
            settingsDict['link'] = productLink
        }

        if (!(docs === "")) {
            let wUrl = docs;
            if (!(wUrl.startsWith("http"))) {
                wUrl = "https://"+ wUrl
            }
            settingsDict["instructions"] = wUrl
        }

        if (!(pID === "")) {
            settingsDict["id"] = pID
        }
        if (!(pName === "")) {
            settingsDict['name'] = pName
        }

        if (!(status === "")) {
            settingsDict['status'] = status
        }

        if (!(sellerVisibility === null)) {
            settingsDict['visibility'] = sellerVisibility
        }

        const tempDict = {0: "green", 1: "yellow", 2:"orange", 3:"blue", 4:"red"}
        settingsDict['statuscolor'] = tempDict[selectedEmoji]

        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const handleDeleteApiKey = () => {
        if (!activeRow) {
            setError("Please select a key to delete")
        }


    }
    const clearNewProductDict = (event) => {
        setNewProductLengths({})
    }
    const handleNewLengthChange = (event) => {
        let val = event.target.value;

        // Remove leading zeros
        val = val.replace(/^0+/, '');

        // If the resulting value is empty, set it to "0"
        if (val === '') {
            val = '0';
        }

        // Convert to number
        const numberVal = parseInt(val, 10);

        // Check if the parsed value is NaN or negative
        if (isNaN(numberVal) || numberVal < 0) {
            setNewLength(0);
        } else {
            setNewLength(numberVal);
        }
    }

    const handleNewPriceChange = (event) => {
        let val = event.target.value.replace(/^0+(?!\.|$)/, '');
        val = val.replace(/^0+(?!\.|$)/, '');
        let FloatValue = parseFloat(val);

        // Check if the parsed value is NaN or negative
        if (isNaN(FloatValue) || FloatValue < 0) {
            // If NaN or negative, set the value to 0
            FloatValue = 0.0;
        }
        setNewPrice(FloatValue)

    }


    const handleAddNewPriceLengthPair = () => {

        try {// Create a new amountDict with the updated value
            const newProductPriceDict = {
                ...newProductLengths,
                [newLength]: newPrice
            };

            // Update the state with the new amountDict
            setNewProductLengths(newProductPriceDict);
            setSuccess("Added length")

        }
        catch(error) {
            setError(error)
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setSuccess('Copied to clipboard');
                // You can add a success message or other feedback here if needed
            })
            .catch((err) => {
                setError('Failed to copy to clipboard');
            });
    };


    const handleApiKey = (event) => {
        setApiKey(event.target.value)
    }
    const handleNewProductIcon = (event) => {
        setNewProductIcon(event.target.value)
    }
    const handleNewProductImages = (event) => {
        setNewProductImages(event.target.value)
    }

    const handleNewProductVideos = (event) => {
        setNewProductVideos(event.target.value)
    }

    const handleNewProductName = (event) => {
        setNewProductName(event.target.value)
    }

    const handleNewProductID = (event) => {
        setNewProductID(event.target.value)
    }

    const toggleResellerVisibility = (event) => {
        setSellerVisibility(event.target.checked)
    }
    const toggleStockedItem = (event) => {
        setStockedItem(event.target.checked)
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value)
    }

    const handleFeatureNameChange = (event, index) => {
        let newName = event.target.value;

        if (newName in featureDict) {
            newName = newName+"-1"
        }

        setFeatureDict(prevDict => {
            const entries = Object.entries(prevDict);
            const updatedEntry = [newName, entries[index][1]]; // Replace name with new name, keep features unchanged

            return Object.fromEntries([
                ...entries.slice(0, index),
                updatedEntry,
                ...entries.slice(index + 1)
            ]);
        });
    };

    const addCustomPriceLength = (length) => {
        let name = 1
        while(true) {
            if (name in customPricingDict[length]) {
                name++
            } else {
                break
            }
        }

        setCustomPricingDict(prevDict => ({
            ...prevDict,
            [length]: {
                ...prevDict[length],
                [name]: 0
            }
        }));
        console.log(customPricingDict);
    }

    const setPriceDict = (length, value) => {
        let formattedValue = value.replace(/^0+/, '');
        if (formattedValue === '') {
            formattedValue = "0";
        }
        if (formattedValue.startsWith("0") && formattedValue.length > 1) {
            formattedValue = formattedValue.substring(1, formattedValue.length)
        } else {
            // Convert the value to a float
            formattedValue = parseFloat(formattedValue);
            if (isNaN(formattedValue)) {
                // Handle cases where parseFloat fails (e.g., input is non-numeric)
                formattedValue = '';
            }
        }

        // Create a new amountDict with the updated value
        const newAmountDict = {
            ...amountDict,
            [length]: formattedValue
        };

        // Update the state with the new amountDict
        setAmountDict(newAmountDict);

    };


    const setProductEvent = (event) => {
        setProduct(event)
        let temp = {}
        for (const keyLen of Object.keys(menuItems[event].lengths)) {
            temp[keyLen] = ""
        }
        setCustomPricingDict(temp)
        // set status
        setStatus(menuItems[event].status)
        setSelectedEmoji(parseInt(menuItems[event].statuscolor))
        // testest
        setAmountDict(menuItems[event].lengths)
        // set visibility of product for resellers
        setSellerVisibility(menuItems[event].visibility)
        setIsFeatured(menuItems[event].featured)


    }

    const handleSetSelectedPriceLength = (event) => {
        setSelectedPriceLength(event)
    }

    const handleSetProductImageLinks = (text) => {
        setProductImageLinks(text)
    }
    const handleSetProductVideoLinks = (event) => {
        setProductVideoLinks(event)
    }

    const handleSaveCustomPricing = async () => {
        const settingsDict = {custompricing: customPricingDict}
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const handleSearch = (event) => {
        setSearchFieldValue(event.target.value)
    }

    useEffect(() => {
        // This function will run before anything else loads
        initKeyData()
    }, []);


    const handleSetFeatures = (event, category) => {
        setFeatureDict(prevDict => ({
            ...prevDict,
            [category]: event.target.value
        }));
    }

    const handleLoaderChange = (event) => {
        setLoader(event)
    }

    const handleDocsChange = (event) => {
        setDocs(event)
    }

    const handleIconChange = (event) => {
        setIcon(event)
    }

    const handleFeaturePopup = () =>{
        let newFeatureDict = {}
        for (let [category, featureList] of Object.entries(menuItems[product].features)) {
            newFeatureDict[category] = featureList || "";
        }
        setFeatureDict(newFeatureDict)
        setFeaturePopup(!featurePopup)
    }

    const handleDeleteFeature = (name) => {
        setFeatureDict(prevDict => ({
            ...prevDict,
            [name]: "" // Empty feature marked for deletion server side
        }));
    };

    const handleSaveFeatures = async () => {
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, {"features":featureDict});

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }


    const handleRemoveCustomPrice = (minAmount) => {
        setCustomPricingDict(prevDict => {
            // Destructure and omit the `minAmount` key from the selected length's dict
            const {[minAmount]: _, ...updatedMinAmounts} = prevDict[selectedPriceLength] || {};

            return {
                ...prevDict,
                [selectedPriceLength]: updatedMinAmounts
            };
        });
    };
    const handleSetCustomLenPrice = (price, minAmount, length) => {
        let newPrice = price;

        // Remove non-numeric characters except for one decimal point
        newPrice = newPrice.replace(/[^0-9.]/g, '');

        // Set to "0" if empty
        if (newPrice === "") {
            newPrice = "0";
        }

        // Remove leading zero if more than one character
        if (newPrice.length > 1 && newPrice.startsWith("0") && !newPrice.startsWith("0.")) {
            newPrice = newPrice.substring(1);
        }

        // Allow only one decimal point
        if ((newPrice.match(/\./g) || []).length > 1) {
            newPrice = newPrice.slice(0, newPrice.lastIndexOf('.'));
        }

        setCustomPricingDict(prevDict => ({
            ...prevDict,
            [length]: {
                ...prevDict[length],
                [minAmount]: newPrice
            }
        }));

    };




    return (
        <div className="PageDefaults">
            <div className={"BoxItemFlexBox"}>
                <div className={"ProductManage"}>
                    <h2>Select Product</h2>
                    <div>
                        <div className={"BoxItem"}>
                            <div className={"ProductSettingsFlexbox"}>
                                <div className={"PricingSettings"}>
                                    <div>
                                        <div>

                                            <div className={"hSort"}>
                                                <TextFieldComp type={"text"}
                                                               label={`Search for ${isOn ? "Category" : "Product"}`}
                                                               placeholder={`${isOn ? "Category" : "Product"} Name`}
                                                               isMultiline={false}
                                                               boxWidth={"23ch"}
                                                               onChangeFunc={handleSearch}
                                                               style={{marginTop: "2ch"}}/>
                                                <div style={{marginTop: "3ch", marginRight: "2ch"}}>
                                                    <SwitchComp isOn={isOn} offText={"Products"} onText={"Categories"}
                                                                handleChange={handleSetEditType}/>
                                                </div>
                                            </div>


                                        </div>
                                        <div className={"HistoryResults"}>
                                            <ul className={"HistoryList"} ref={historyListRef}>
                                                {!Object.keys(menuItems) && (
                                                    <li className="row" id={0}>
                                                        <div className={"rowText"}>
                                                            <div id="title">
                                                                <p>
                                                                    You have no products.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                                {searchFieldValue ? (
                                                    <>
                                                        {Object.entries(loopItem).map(([key, val]) => {
                                                            const {color, icon: Icon} = emojis[val.statuscolor] || {};
                                                            return(
                                                            <>
                                                                {(key.toLowerCase().startsWith(searchFieldValue.toLowerCase()) || key.toLowerCase().includes(searchFieldValue.toLowerCase())) && (
                                                                    <li key={key}
                                                                        className={`row ${activeRow === key ? 'active' : ''}`}
                                                                        id={key} onClick={() => handleRowClick(key)}>
                                                                        <div id="title">
                                                                            <div className={"rowText"}>
                                                                                {isOn ? (
                                                                                    <p>{key}</p>
                                                                                ) : (
                                                                                    <p>
                                                                                        <em>{key}</em> <br/>
                                                                                        <div className={"hSort"}
                                                                                             style={{
                                                                                                 justifyContent: "flex-start",
                                                                                                 alignItems: "center",
                                                                                                 gap: "0.5ch"
                                                                                             }}>
                                                                                            <em>{Icon && <Icon
                                                                                                sx={{color: color}}/>}</em>
                                                                                            <p>{val.status}</p>
                                                                                        </div>

                                                                                        {keyCount[key] ? (Object.entries(keyCount[key]).map(([length, count], index) => (

                                                                                            <div key={length}>
                                                                                                <em>{length}: </em>{count}<br/>
                                                                                            </div>
                                                                                        ))) : (
                                                                                            <p>No stock available.</p>
                                                                                        )}
                                                                                    </p>
                                                                                )}

                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )}

                                                            </>
                                                        )})}

                                                    </>
                                                ) : (
                                                    <>
                                                        {Object.entries(loopItem).map(([key, val]) => {
                                                            const {color, icon: Icon} = emojis[val.statuscolor] || {};
                                                            return (
                                                            <li key={key}
                                                                className={`row ${activeRow === key ? 'active' : ''}`}
                                                                id={key} onClick={() => handleRowClick(key)}>
                                                                <div id="title">
                                                                    <div className={"rowText"}>
                                                                        {isOn ? (
                                                                            <p>{key}</p>
                                                                        ) : (
                                                                            <p>
                                                                                <em>{key}</em> <br/>
                                                                                <div className={"hSort"} style={{justifyContent: "flex-start", alignItems: "center", gap: "0.5ch"}}>
                                                                                    <em>{Icon && <Icon sx={{color: color}}/>}</em>
                                                                                    <p>{val.status}</p>
                                                                                </div>

                                                                                {keyCount[key] ? (Object.entries(keyCount[key]).map(([length, count], index) => (

                                                                                    <div key={length}>
                                                                                        <em>{length}: </em>{count}<br/>
                                                                                    </div>
                                                                                ))) : (
                                                                                    <p>No stock available.</p>
                                                                                )}
                                                                            </p>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )})}
                                                    </>
                                                )}

                                            </ul>
                                            <button onClick={handleAddProduct}
                                                    style={{marginTop: "2ch", marginBottom: "2ch"}}>
                                                New Product
                                            </button>
                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>
                        <Popup toggleOpen={handlePricingPopup} isOpen={pricingPopup} size={"lg"} btnText={"Save Custom Pricing"} btnCallback={handleSaveCustomPricing}>
                            <div className={"vSort"}>
                                <div className={"BoxScrollContainer"} style={{maxHeight: "500px"}}>
                                    <div className="vSort">

                                        {product && Object.keys(menuItems[product].lengths).map((value, index) => (
                                            <>
                                                <p>{value} Prices</p>
                                                <TextFieldComp type={"text"}
                                                               placeholder={"Key amount:Price"}
                                                               isMultiline={true}
                                                               label={`Seperate by Newline (key amount:price)`}
                                                               value={customPricingDict[value]}
                                                               onChangeFunc={e =>
                                                                   setCustomPricingDict(prevDict => ({
                                                                       ...prevDict,
                                                                       [value]: e.target.value
                                                                   }))
                                                               }/>
                                            </>
                                        ))}

                                    </div>

                                </div>

                            </div>
                        </Popup>

                        <Popup toggleOpen={handleAddProduct} isOpen={isPopupOpen} size={"lg"} btnCallback={createNewProduct} btnText={"Add Product"}>
                            <div className={"hSort"}>
                                <div className="vSort">
                                    <p>Product Name</p>
                                    <TextFieldComp isMultiline={false} type={"text"} label={"Enter Name"}
                                                   placeholder={"Case Sensitive"}
                                                   onChangeFunc={handleNewProductName}/>
                                    <p>Product ID</p>
                                    <TextFieldComp isMultiline={false} type={"text"} label={"Enter ID"}
                                                   placeholder={"Integers only for venom products"}
                                                   onChangeFunc={handleNewProductID}/>
                                    <CheckboxComp onChangeFunc={toggleStockedItem} label={"Stocked Item"}/>
                                    <CheckboxComp onChangeFunc={toggleResellerVisibility}
                                                  label={"Public For Resellers"}/>
                                    <p>Product Icon</p>
                                    <TextFieldComp isMultiline={true} type={"text"} label={"Icon Link"}
                                                   placeholder={"https://..."}
                                                   onChangeFunc={handleNewProductIcon}
                                    />
                                    <p>Product Images</p>
                                    <TextFieldComp isMultiline={true} type={"text"} label={"Image Links"}
                                                   placeholder={"https://..."}
                                                   onChangeFunc={handleNewProductImages}/>
                                    <p>Product Videos</p>
                                    <TextFieldComp isMultiline={true} type={"text"} label={"Video Links"}
                                                   placeholder={"https://..."}
                                                   onChangeFunc={handleNewProductVideos}/>

                                </div>

                                <div className={"vSort"}>
                                    <div>
                                        <p>Enter Pricing Details</p>
                                        <TextFieldComp type={"number"} label={"Amount of Days"}
                                                       placeholder={"Integers Only"}
                                                       onChangeFunc={handleNewLengthChange}
                                                       value={newLength.toString()}/>
                                        <TextFieldComp type={"number"} label={"Price"} placeholder={""}
                                                       onChangeFunc={handleNewPriceChange}
                                                       value={newPrice.toString()}/>
                                        <button onClick={handleAddNewPriceLengthPair}>Add New Length</button>
                                        <button style={{marginLeft: "1ch"}}
                                                onClick={clearNewProductDict}>Clear
                                        </button>
                                        <p>Add the public prices here.<br/>
                                            Custom prices for sellers can be<br/> added
                                            after product is created.</p>
                                        <ul className={"iconlist"}></ul>
                                        {newProductLengths && Object.keys(newProductLengths)
                                            .sort((a, b) => parseInt(a) - parseInt(b))
                                            .map((key, value) => (
                                                <div className={"iconlistitem"}>
                                                    <p>
                                                        <em>{key} Day{!(key === "1") && ("s")}:</em> $ {newProductLengths[key]}
                                                    </p>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="vSort">
                                    <div className={"addIconSort"}>
                                        <p>Enter Features </p>
                                        <AddIcon onClick={addNewFeature} className={"icon"}/>
                                    </div>
                                    <div className={"BoxScrollContainer"}>
                                        {Object.entries(featureDict).map(([name, features], index) => (
                                            <div className={"vSort"}>
                                                <div className={"hSort"}>
                                                    <ThemeProvider theme={myTheme}>
                                                        <Box
                                                            component="form"
                                                            sx={{
                                                                '& > :not(style)': {
                                                                    m: 1,
                                                                    width: '22ch',
                                                                    color: "white",
                                                                    backgroundColor: myTheme.palette.background.main,
                                                                },
                                                                '& input': {
                                                                    color: 'white', // Set text color to white
                                                                },
                                                                '& .MuiInputBase-inputMultiline': {
                                                                    color: 'white', // Set text color to white for multiline input
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                    },
                                                                }, maxHeight: '150px',
                                                                overflow: 'auto'

                                                            }}
                                                            noValidate
                                                            autoComplete="off">
                                                            <TextField variant={"outlined"} color={"primary"}
                                                                       value={name}
                                                                       label={"Set Category Name"}
                                                                       placeholder={"Category Name..."}
                                                                       onChange={(event) => handleFeatureNameChange(event, index)}
                                                                       focused/>

                                                        </Box>
                                                    </ThemeProvider>
                                                    <div className={"addIconSort"}>
                                                        <CloseIcon onClick={() => handleDeleteFeature(name)}
                                                                   className={"icon"}/>
                                                    </div>
                                                </div>
                                                <ThemeProvider theme={myTheme}>
                                                    <Box
                                                        component="form"
                                                        sx={{
                                                            '& > :not(style)': {
                                                                m: 1,
                                                                width: '25ch',
                                                                color: "white",
                                                                backgroundColor: myTheme.palette.background.main,
                                                            },
                                                            '& input': {
                                                                color: 'white', // Set text color to white
                                                            },
                                                            '& .MuiInputBase-inputMultiline': {
                                                                color: 'white', // Set text color to white for multiline input
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                },
                                                            }, maxHeight: '150px',
                                                            overflow: 'auto'

                                                        }}
                                                        noValidate
                                                        autoComplete="off">

                                                        <TextField variant={"outlined"} color={"primary"}
                                                                   value={features} multiline
                                                                   label={"Features"}
                                                                   placeholder={"Enter Features"}
                                                                   onChange={(event) => handleSetFeatures(event, name)}
                                                                   focused/>


                                                    </Box>
                                                </ThemeProvider>

                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>

                        </Popup>

                    </div>
                </div>


                {!(product === "") && (
                    <>
                        {isOn ? (
                            <div className={"boxItem"}>
                                test
                            </div>
                        ) : (
                            <div className={"ProductManage"}>
                                <h2>Product Details</h2>
                                <div className={"BoxItem"}>
                                    <div className={"BoxScrollContainer"}>
                                        <div className={"ProductSettingsFlexbox"}>
                                            <div className={"OtherSettings"}>

                                                <p><em>ID:</em> {menuItems[product].id}</p>
                                                <p>Change Product Status</p>
                                                {menuItems && menuItems[product] && menuItems[product].status ? (
                                                    <p><em>Status:</em> {menuItems[product].status}</p>
                                                ) : (
                                                    <p><em>Status not available </em></p>
                                                )}

                                                <TextFieldComp label={"Enter Status"} value={status}
                                                               placeholder={"Status"}
                                                               type={"text"}
                                                               onChangeFunc={handleStatusChange}/>

                                                <div>
                                                    <p>Select Status Color</p>
                                                    <div className={"emoji-container"}>
                                                        {Object.entries(emojis).map(([emoji, data], index) => {
                                                            const color = data?.color || ""; // Default to an empty string if `data.color` is undefined
                                                            const Icon = data?.icon || null; // Default to `null` if `data.icon` is undefined

                                                            return (
                                                            <div
                                                                key={index}
                                                                onClick={() => setSelectedEmoji(index)}
                                                                className={`emoji ${selectedEmoji === index ? 'selected' : ''}`}
                                                            >
                                                                {Icon &&
                                                                    <Icon className={"icon"} sx={{color: color}}/>}
                                                            </div>
                                                            )})}
                                                    </div>
                                                </div>
                                                <div className={"toolTipContainer"}>
                                                    <CheckboxComp onChangeFunc={(e) => setIsFeatured(e.target.checked)}
                                                                  label={"Featured"}
                                                                  checked={isFeatured}/>
                                                    <ToolTip tooltipText={"This will add/remove this product from the list of featured products, displayed on the featured products embed (or if you've implemented this into your website, it will have the same effect)"} boxWidth={"20ch"}/>
                                                </div>

                                                <div>
                                                    <button onClick={setStatusInfo}>
                                                        Save Status
                                                    </button>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div>
                                                <button onClick={handleToggleApiPopup} style={{marginTop: 0}}>
                                                    Get API Links
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={handleToggleBundleApiPopup}>
                                                    Get Bundle API Links
                                                </button>
                                            </div>
                                            <hr/>
                                            <p>Set Loader Link</p>
                                            {menuItems[product].loader && (
                                                <a style={{color: "white"}} href={menuItems[product].loader}>Current
                                                    Loader</a>
                                            )}

                                            <TextFieldComp label={"Enter Link"} value={loader}
                                                           placeholder={"https://...."}
                                                           type={"text"}
                                                           onChangeFunc={e=> handleLoaderChange(e.target.value)}/>

                                            <p>Set Product Instructions Link</p>
                                            {menuItems[product].instructions && (
                                                <a style={{color: "white"}} href={menuItems[product].instructions}>Current
                                                    Instructions</a>
                                            )}
                                            <TextFieldComp label={"Enter Link"} value={docs}
                                                           placeholder={"https://...."}
                                                           type={"text"}
                                                           onChangeFunc={e=> handleDocsChange(e.target.value)}/>
                                            {/*
                                            <p>Set Product Website Link</p>
                                            {menuItems[product].link && (
                                                <a style={{color: "white"}} href={menuItems[product].link}>Current
                                                    Website Link</a>
                                            )}
                                            <TextFieldComp label={"Enter Link"} value={productLink}
                                                           placeholder={"https://...."}
                                                           type={"text"}
                                                           onChangeFunc={handleLinkChange}/>
*/}

                                            <Popup toggleOpen={handleFeaturePopup} isOpen={featurePopup} size={"lg"}
                                                   btnCallback={handleSaveFeatures} btnText={"Save Features"}>
                                                <div className={"hSort"}>
                                                    <div className={"BoxScrollContainer"}>
                                                        <div className="vSort">

                                                            <div className={"addIconSort"}>

                                                                <p>Enter Features </p>
                                                                <AddIcon onClick={addNewFeature}
                                                                         className={"icon"}/>
                                                            </div>

                                                            {Object.entries(featureDict).map(([name, features], index) => (
                                                                <div className={"vSort"} style={{
                                                                    marginTop: "2ch",
                                                                    marginBottom: "2ch"
                                                                }}>
                                                                    <div className={"hSort"}>
                                                                        <ThemeProvider theme={myTheme}>
                                                                            <Box
                                                                                component="form"
                                                                                sx={{
                                                                                    '& > :not(style)': {
                                                                                        m: 1,
                                                                                        width: '22ch',
                                                                                        color: "white",
                                                                                        backgroundColor: myTheme.palette.background.main,
                                                                                    },
                                                                                    '& input': {
                                                                                        color: 'white', // Set text color to white
                                                                                    },
                                                                                    '& .MuiInputBase-inputMultiline': {
                                                                                        color: 'white', // Set text color to white for multiline input
                                                                                    },
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                                        },
                                                                                    },
                                                                                    maxHeight: '150px',
                                                                                    overflow: 'auto'

                                                                                }}
                                                                                noValidate
                                                                                autoComplete="off">
                                                                                <TextField
                                                                                    variant={"outlined"}
                                                                                    color={"primary"}
                                                                                    value={name}
                                                                                    label={"Set Category Name"}
                                                                                    placeholder={"Category Name..."}
                                                                                    onChange={(event) => handleFeatureNameChange(event, index)}
                                                                                    focused/>

                                                                            </Box>
                                                                        </ThemeProvider>
                                                                        <div className={"addIconSort"}>
                                                                            <CloseIcon
                                                                                onClick={() => handleDeleteFeature(name)}
                                                                                className={"icon"}/>
                                                                        </div>
                                                                    </div>
                                                                    <ThemeProvider theme={myTheme}>
                                                                        <Box
                                                                            component="form"
                                                                            sx={{
                                                                                '& > :not(style)': {
                                                                                    m: 1,
                                                                                    width: '25ch',
                                                                                    color: "white",
                                                                                    backgroundColor: myTheme.palette.background.main,
                                                                                },
                                                                                '& input': {
                                                                                    color: 'white', // Set text color to white
                                                                                },
                                                                                '& .MuiInputBase-inputMultiline': {
                                                                                    color: 'white', // Set text color to white for multiline input
                                                                                },
                                                                                '& .MuiOutlinedInput-root': {
                                                                                    '& fieldset': {
                                                                                        borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                                    },
                                                                                    '&:hover fieldset': {
                                                                                        borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                                    },
                                                                                    '&.Mui-focused fieldset': {
                                                                                        borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                                    },
                                                                                },
                                                                                maxHeight: '150px',
                                                                                overflow: 'auto'

                                                                            }}
                                                                            noValidate
                                                                            autoComplete="off">

                                                                            <TextField variant={"outlined"}
                                                                                       color={"primary"}
                                                                                       value={features}
                                                                                       multiline
                                                                                       label={"Features"}
                                                                                       placeholder={"Enter Features"}
                                                                                       onChange={(event) => handleSetFeatures(event, name)}
                                                                                       focused/>


                                                                        </Box>
                                                                    </ThemeProvider>

                                                                </div>
                                                            ))}

                                                        </div>

                                                    </div>
                                                </div>
                                            </Popup>


                                            <CheckboxComp onChangeFunc={toggleResellerVisibility}
                                                          label={"Public For Resellers"}
                                                          checked={sellerVisibility}/>
                                            <div>
                                                <button onClick={setProductLinks}>
                                                    Save Links
                                                </button>
                                            </div>
                                            <div>
                                                <Popup toggleOpen={() => setIsBundleApiPopupOpen(false)}
                                                       isOpen={isBundleApiPopupOpen} size={"lg"}>
                                                    <div className={"hSort"}>
                                                        {!apiLinkDict ? (
                                                            <div className="vSort">
                                                                <p>Select First Product</p>
                                                                <SelectComp menuItems={menuItems}
                                                                            label={"Product One"}
                                                                            onChangeFunc={handleSetBundleProductOne}/>
                                                                <p>Select First Product</p>
                                                                <SelectComp menuItems={menuItems}
                                                                            label={"Product Two"}
                                                                            onChangeFunc={handleSetBundleProductTwo}/>
                                                                <p>Enter API Key</p>
                                                                <TextFieldComp isMultiline={false} type={"text"}
                                                                               label={"Api Key"}
                                                                               placeholder={""}
                                                                               onChangeFunc={handleApiKey}/>
                                                                <div>
                                                                    <button onClick={handleBundleAPILinks}>
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="vSort">
                                                                <h2>API Links
                                                                    for <em>{bundleProductOne}</em> and <em>{bundleProductTwo}</em>
                                                                </h2>
                                                                {apiLinkDict && Object.entries(apiLinkDict).sort(([keyA], [keyB]) => {
                                                                    const numA = parseInt(keyA.split(" ")[0]); // Extract numeric part of keyA
                                                                    const numB = parseInt(keyB.split(" ")[0]); // Extract numeric part of keyB
                                                                    return numA - numB; // Sort numerically
                                                                }).map(([key, value]) => (
                                                                    <div key={key} style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <p style={{marginRight: '8px'}}>
                                                                            <em>{key}:</em> {value.substring(0,50) + "..."}
                                                                        </p>
                                                                        <IconButton className="copyButton"
                                                                                    onClick={() => handleCopy(value)}
                                                                                    size={"small"}>
                                                                            <ContentCopyIcon sx={{
                                                                                fontSize: 20,
                                                                                color: "white",
                                                                                backgroundColor: "transparent"
                                                                            }}/>
                                                                        </IconButton>
                                                                    </div>
                                                                ))}
                                                                <p style={{maxWidth: "550px"}}><em>NOTE:</em> The
                                                                    links generated are only for same lengths,
                                                                    however lengths can be mixed and matched <em>manually</em> as long
                                                                    as they are available per product.</p>
                                                                <div>
                                                                    <button onClick={handleRemoveApiKey}>
                                                                        Change API Key
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}


                                                    </div>
                                                </Popup>
                                                <hr/>
                                                <p>Set Product Features</p>
                                                <div>
                                                    <button style={{marginTop: "-2ch", marginBottom: "1ch"}}
                                                            onClick={handleFeaturePopup}>Change Features
                                                    </button>
                                                </div>
                                                <hr/>
                                                <p>Set Product Logo</p>
                                                <div>
                                                    <div className={"toolTipContainer"}>
                                                        <TextFieldComp label={"Enter Icon Link"} value={icon}
                                                                       placeholder={"https://...."}
                                                                       type={"text"}
                                                                       boxWidth={"14ch"}
                                                                       onChangeFunc={e=> handleIconChange(e.target.value)}/>
                                                        <div className={"popupImage"}>
                                                            <img
                                                                src={menuItems[product].logo || "favicon.ico"}
                                                                style={{
                                                                    width: "100px",
                                                                    border: "2px transparent",
                                                                    borderRadius: "10px"
                                                                }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>Set Product Images</p>
                                                <div>
                                                    <TextFieldComp label={"Image Links"} placeholder={"https://..."}
                                                                   isMultiline={true}
                                                                   value={productImageLinks}
                                                                   onChangeFunc={e=> handleSetProductImageLinks(e.target.value)}/>
                                                </div>

                                                <p>Set Product Videos</p>
                                                <div>
                                                    <TextFieldComp label={"Video Links"} placeholder={"https://..."}
                                                                   isMultiline={true}
                                                                   value={productVideoLinks}
                                                                   onChangeFunc={e=> handleSetProductVideoLinks(e.target.value)}/>
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={setMediainfo}>Save Media
                                                    </button>
                                                </div>
                                                <hr/>
                                                <Popup toggleOpen={handleToggleApiPopup} isOpen={isApiPopupOpen}
                                                       size={"lg"}>
                                                    <div className={"hSort"}>
                                                        {!apiLinkDict ? (
                                                            <div className="vSort">
                                                                <p>Enter API Key</p>
                                                                <TextFieldComp isMultiline={false} type={"text"}
                                                                               label={"Api Key"}
                                                                               placeholder={""}
                                                                               onChangeFunc={handleApiKey}/>
                                                                <div>
                                                                    <button onClick={handleAPILinks}>
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="vSort">
                                                                <h2>API Links for <em>{product}</em></h2>
                                                                {apiLinkDict && Object.entries(apiLinkDict).sort(([keyA], [keyB]) => {
                                                                    const numA = parseInt(keyA.split(" ")[0]); // Extract numeric part of keyA
                                                                    const numB = parseInt(keyB.split(" ")[0]); // Extract numeric part of keyB
                                                                    return numA - numB; // Sort numerically
                                                                }).map(([key, value]) => (
                                                                    <div key={key} style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <p style={{marginRight: '8px'}}>
                                                                            <em>{key}: </em>{value.substring(0,20)+"..."}
                                                                        </p>
                                                                        <IconButton className="copyButton"
                                                                                    onClick={() => handleCopy(value)}
                                                                                    size={"small"}>
                                                                            <ContentCopyIcon sx={{
                                                                                fontSize: 20,
                                                                                color: "white",
                                                                                backgroundColor: "transparent"
                                                                            }}/>
                                                                        </IconButton>
                                                                    </div>
                                                                ))}
                                                                <div>
                                                                    <button onClick={handleRemoveApiKey}>
                                                                        Change API Key
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}


                                                    </div>
                                                </Popup>

                                                <p>Product Pricing</p>

                                                {product && menuItems[product] && menuItems[product].lengths &&
                                                    Object.keys(menuItems[product].lengths)
                                                        .sort((a, b) => parseInt(a) - parseInt(b))
                                                        .map((length) => {
                                                            const price = menuItems[product].lengths[length];
                                                            return (
                                                                <>
                                                                    <p>
                                                                        <em>{length.charAt(0).toUpperCase() + length.slice(1)}</em> {" (Current: $ " + price + ")"}
                                                                    </p>
                                                                    <ThemeProvider theme={myTheme}>
                                                                        <Box
                                                                            component="form"
                                                                            sx={{
                                                                                '& > :not(style)': {
                                                                                    m: 1,
                                                                                    width: '25ch',
                                                                                    color: "white",
                                                                                    backgroundColor: myTheme.palette.background.main,
                                                                                },
                                                                                '& input': {
                                                                                    color: 'white', // Set text color to white
                                                                                },
                                                                                '& .MuiOutlinedInput-root': {
                                                                                    '& fieldset': {
                                                                                        borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                                    },
                                                                                    '&:hover fieldset': {
                                                                                        borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                                    },
                                                                                    '&.Mui-focused fieldset': {
                                                                                        borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                                    },
                                                                                },
                                                                            }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        type="number" // Change type to "text"
                                                                        onChange={(event) => setPriceDict(length, event.target.value.replace(/^0+/, ''))} // Remove leading zeros
                                                                        color="primary"
                                                                        label={"Set Price"}
                                                                        variant="outlined"
                                                                        focused
                                                                        value={amountDict[length] && amountDict[length].toString() || '0'} // Display the value as is from the state
                                                                        inputProps={{min: "0", maxLength: 5}}
                                                                    />
                                                                </Box>
                                                            </ThemeProvider>
                                                        </>

                                                    )
                                                        ;
                                                })}
                                        </div>

                                    </div>
                                    <div>
                                        <button onClick={setProductPricing}>
                                            Save Pricing
                                        </button>
                                    </div>
                                    <div>
                                        <button onClick={handlePricingPopup}>
                                            Use Custom Pricing
                                        </button>
                                    </div>
                                    <hr/>
                                    <div>
                                        <button onClick={handleDeleteProduct} style={{marginTop: 0}}>
                                            Delete Product
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                            )}
                        </>
                    )}
                {!isOn ? (
                <div className="ProductManage">
                    <h2>Stock Items</h2>
                    <div className="BoxItem">
                        {!(product === "") && menuItems[product] && menuItems[product].stocked ? (
                            <>
                                <div>
                                    {menuItems[product].stocked ? (
                                        <div className={"BoxScrollContainer"} style={{maxHeight: "800px"}}>
                                            {product && menuItems[product] && menuItems[product].lengths &&
                                                Object.keys(menuItems[product].lengths)
                                                    .sort((a, b) => parseInt(a) - parseInt(b))
                                                    .map((length) => {
                                                        return (
                                                            <>
                                                                <p>{length.charAt(0).toUpperCase() + length.slice(1)}<br/>


                                                                    <em>Count: </em>{keyCount[product][length]}</p>

                                                                <ThemeProvider theme={myTheme}>
                                                                    <Box
                                                                        component="form"
                                                                        sx={{
                                                                            '& > :not(style)': {
                                                                                m: 1,
                                                                                width: '25ch',
                                                                                color: "white",
                                                                                backgroundColor: myTheme.palette.background.main,
                                                                            },
                                                                            '& input': {
                                                                                color: 'white', // Set text color to white
                                                                            },
                                                                            '& .MuiInputBase-inputMultiline': {
                                                                                color: 'white', // Set text color to white for multiline input
                                                                            },
                                                                            '& .MuiOutlinedInput-root': {
                                                                                '& fieldset': {
                                                                                    borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                                },
                                                                                '&:hover fieldset': {
                                                                                    borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                                },
                                                                                '&.Mui-focused fieldset': {
                                                                                    borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                                },
                                                                            }, maxHeight: '200px',
                                                                            overflow: 'auto'

                                                                        }}
                                                                        noValidate
                                                                        autoComplete="off">
                                                                        <TextField variant={"outlined"}
                                                                                   color={"primary"}
                                                                                   value={keyDict[product][length]}
                                                                                   multiline label={"Keys"}
                                                                                   placeholder={"Seperate with Newline"}
                                                                                   onChange={(text) => handleSetKeyDict(length, text)}
                                                                                   focused/>
                                                                    </Box>
                                                                </ThemeProvider>

                                                            </>
                                                        );
                                                    })}

                                        </div>

                                    ) : (
                                        <p>Nothing to stock.</p>
                                    )}
                                </div>
                                <div>
                                    <button onClick={handleRestock}>
                                        Save Stock
                                    </button>
                                </div>
                            </>
                        ) : (<p>This product is not stocked.</p>)}
                    </div>

                </div>


                    ) : (
                        <>

                            <div className={"vSort"} style={{textAlign: "center"}}>
                                <h2>Category Info</h2>
                                <div className={"BoxItem"} style={{textAlign: "start"}}>
                                    {activeRow ? (
                                        <>
                                            <p>Set Category Name</p>
                                            <TextFieldComp type={"text"} label={"Category Name"} placeholder={activeRow} value={categorySettings['name']} onChangeFunc={e=> handleEditCategorySettings("name", e.target.value)} />
                                            <p>Category Image</p>
                                            <TextFieldComp type={"text"} label={"Image Link"} placeholder={"https://..."} value={categorySettings['image']} onChangeFunc={e=> handleEditCategorySettings("image", e.target.value)} />
                                                <img src={categoryItems[activeRow].image} alt={""} width={280} className={"categoryImage"} style={{marginTop: "2ch", marginBottom: "2ch"}}/>
                                            <div className={"toolTipContainer"} style={{ marginBottom: "2ch"}}>
                                                <p>Message of the Day</p>
                                                <ToolTip tooltipText={"This will display on product embeds. It can also be retrieved from the JSON data per category when implementing panel into your website."} />
                                            </div>
                                            <TextFieldComp type={"text"} label={"MOTD"} placeholder={activeRow['motd']} onChangeFunc={e=> handleEditCategorySettings("motd", e.target.value)} />

                                            <CheckboxComp onChangeFunc={e=> handleEditCategorySettings("visibility", e.target.checked)} label={"Show Category"} checked={categorySettings['visibility']} />
                                            <div className={"toolTipContainer"} style={{marginTop: "2ch", marginBottom: "2ch"}}>
                                            <p>How to sort products?</p>
                                            <ToolTip tooltipText={"In a product's settings, you can set what category it is located in."} />
                                        </div>
                                            <div>
                                                <button onClick={saveCategorySettings}>Save Category</button>
                                            </div>
                                        </>
                                            ) : (
                                            <p>Please select a category</p>
                                    )}

                                </div>
                            </div>
                        </>
                )}

            </div>
        </div>
    );
}

export default ProductSettings;