import React, {useRef} from "react";


export default function Popup({isOpen, toggleOpen, size, children, btnCallback, btnText}) {
    const contentRef = useRef(null);
    const backgrondRef = useRef(null);
    const handleClose = () => {
        contentRef.current.classList.add("popupFadeOut");
        backgrondRef.current.classList.add("popupBackgroundFadeOut");

        // Trigger the parent to update isOpen after the animation
        setTimeout(() => {
            toggleOpen();
        }, 250); // Match the animation duration
    };

    return (
        <>
        {isOpen && (
            <div className="popup" onClick={handleClose} ref={backgrondRef}>
                <div ref={contentRef} onClick={(e) => e.stopPropagation()} className={size ? ("popup-content-large") : ("popup-content") }>
                    <span className="close-btn" onClick={handleClose}>&times;</span>
                    {children}
                </div>
                {btnCallback && (
                    <button onClick={e => btnCallback()}>
                        {btnText ? btnText : "Submit"}

                    </button>
                )}
            </div>
        )}
        </>
    )

}
